import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  intensity: 50,
  currentColor: 'rgb(255,255,255)',
  wavelength: 400,
  isColor: false,
  isToggleOn: false
};

const colorsSlice = createSlice({
  name: 'colors',
  initialState,
  reducers: {
    setIntensity: (state, action) => {
      state.intensity = action.payload;
    },
    setCurrentColor: (state, action) => {
      state.currentColor = action.payload;
    },
    setWavelength: (state, action) => { 
      state.wavelength = action.payload;
    },
    setIsColor: (state, action) => { 
      state.isColor = action.payload;
    },
    setIsToggleOn: (state, action) => { 
      state.isToggleOn = action.payload;
    }
  },
});

export const {
  setIntensity,
  setCurrentColor,
  setWavelength,
  setIsColor,
  setIsToggleOn
} = colorsSlice.actions;

export default colorsSlice.reducer;
