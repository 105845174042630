import '../../gallery.css'
import React, { useState, useEffect } from 'react'; 
import { enUS, da} from 'date-fns/locale'
import { ReactComponent as Checkbox } from '../../../assets/gallery/checkbox.svg';
import { ReactComponent as CheckboxSelected } from '../../../assets/gallery/checkbox-selected.svg';
import { useTranslation } from 'react-i18next';

import {
    eachWeekOfInterval,
    eachMonthOfInterval,
    endOfWeek,
    endOfMonth,
    getWeek,
    format,
  } from 'date-fns';
  
  export const DatePeriod = ({ images, timeInterval, isSelectMode, onSelectionChange, onImageClick  }) => {
    const [sectionSelections, setSectionSelections] = useState({});
    const [selectedImages, setSelectedImages] = useState([]);
    const { i18n } = useTranslation();
    const { t } = useTranslation('gallery');

    const dateLocale = i18n.language === 'da' ? da : enUS;
  
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleImageClick = (imageSrc) => {
      onImageClick(imageSrc);
  };

    useEffect(() => {
      if (!isSelectMode) {
        setSelectedImages([]);
        setSectionSelections({});
      }
    }, [isSelectMode]);

    const toggleImageSelection = (image) => {
      setSelectedImages((prevSelectedImages) => {
        const isAlreadySelected = prevSelectedImages.some(selectedImage => selectedImage.date === image.date);
        const newSelectedImages = isAlreadySelected
          ? prevSelectedImages.filter(selectedImage => selectedImage.date !== image.date)
          : [...prevSelectedImages, image];
        onSelectionChange(newSelectedImages);
        return newSelectedImages;
      });
    };
  
    const toggleSection = (period) => {
      const isCurrentlySelected = sectionSelections[period.title] || false;
      setSectionSelections((prevSelections) => ({
        ...prevSelections,
        [period.title]: !isCurrentlySelected,
      }));
    
      setSelectedImages((prevSelectedImages) => {
        let newSelectedImages;
        if (isCurrentlySelected) {
          newSelectedImages = prevSelectedImages.filter((selectedImage) => 
            !period.images.some((image) => image.date === selectedImage.date));
        } else {
          const unselectedImagesInPeriod = period.images.filter((image) => 
            !prevSelectedImages.some((selectedImage) => image.date === selectedImage.date));
          newSelectedImages = [...prevSelectedImages, ...unselectedImagesInPeriod];
        }
        onSelectionChange(newSelectedImages);
        return newSelectedImages;
      });
    };
  

  const getPeriods = (images, interval) => {
    const sortedImages = images.sort((a, b) => new Date(a.date) - new Date(b.date));
    if (sortedImages.length === 0) {
      return [];
    }
    
    const startDate = new Date(sortedImages[0].date);
    const endDate = new Date(sortedImages[sortedImages.length - 1].date);
    let periods = [];
  
    if (interval === 'week') {
      const weeks = eachWeekOfInterval({ start: startDate, end: endDate });
      weeks.forEach((weekStart) => {
        const weekEnd = endOfWeek(weekStart);
        const weekImages = images.filter((image) =>
          new Date(image.date) >= weekStart && new Date(image.date) <= weekEnd
        );
        if (weekImages.length > 0) {
          periods.push({
            title: `${t('timeInterval.week')} ${getWeek(weekStart)}`,
            startDate: format(weekStart, 'dd MMM yyyy', { locale: dateLocale }),
            endDate: format(weekEnd, 'dd MMM yyyy', { locale: dateLocale }),
            images: weekImages.reverse(),
          });
        }
      });
    } else if (interval === 'month') {
      const months = eachMonthOfInterval({ start: startDate, end: endDate });
      months.forEach((monthStart) => {
        const monthEnd = endOfMonth(monthStart);
        const monthImages = images.filter((image) =>
          new Date(image.date) >= monthStart && new Date(image.date) <= monthEnd
        );
        if (monthImages.length > 0) {
          periods.push({
            title: capitalizeFirstLetter(format(monthStart, 'MMMM yyyy', { locale: dateLocale })), 
            startDate: format(monthStart, 'dd MMM yyyy', { locale: dateLocale }), 
            endDate: format(monthEnd, 'dd MMM yyyy', { locale: dateLocale }),
            images: monthImages.reverse(),
          });
        }
      });
    } else {
      periods.push({
        title: `${t('timeInterval.all_pictures')}`,
        startDate: format(startDate, 'dd MMM yyyy', { locale: dateLocale }),
        endDate: format(endDate, 'dd MMM yyyy', { locale: dateLocale }),
        images: sortedImages.reverse(),
      });
    }
  
    return periods.reverse();
  };
  // Assuming getPeriods is a function that returns the periods with images
  const periods = getPeriods(images, timeInterval, dateLocale);

  const isPeriodSelected = (period) => {
    return period.images.every(image => selectedImages.includes(image));
  };

 return (
  <div className='date-period-sections-wrapper w-full h-full'>
    {periods.map((period, index) => (
      <div key={index} className="date-period-section">
        <div className='date-period-header'>
          <div className='date-period-title flex items-center'>
              {isSelectMode ? (
                    <>
                    {isPeriodSelected(period) ? <CheckboxSelected onClick={() => toggleSection(period)} /> : <Checkbox onClick={() => toggleSection(period)} />}
                    <h3 onClick={() => toggleSection(period)}>{period.title}</h3>
                    </>
              ) : (
                    <h3>{period.title}</h3>
              )}
          </div>
          <div className='date-period-date'>{period.startDate} - {period.endDate}</div>
        </div>
        <div className="images-container">
            {period.images.map((image, imageIndex) => (
              <div key={imageIndex} className="image-selection-wrapper">
                {isSelectMode && (
                  <div onClick={() => toggleImageSelection(image)} className="image-checkbox">
                    {selectedImages.includes(image) ? <CheckboxSelected /> : <Checkbox />}
                  </div>
                )}
                <img
                  className={`gallery-image ${selectedImages.includes(image) ? 'selected' : ''}`}
                  src={`data:image/jpeg;base64,${image.img}`}
                  alt={`Image from ${image.date}`}
                  onClick={() => isSelectMode ? toggleImageSelection(image) : handleImageClick(`data:image/jpeg;base64,${image.img}`)} 
                />
              </div>
            ))}
          </div>
      </div>
    ))}
  </div>
);
};