import './gallery.css';
import React, { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { takePictureDevice } from './functions/controls/controlsEndpointFunctions'
import { getHistoricalImagesDevice, deletePicturesDevice, downloadSelectedImages, downloadTimelapse } from './functions/gallery/imageFunctions';
import { Dropdown } from './global/selectors';
import { useTranslation } from 'react-i18next';
import Button from './global/buttons';
import { IconButton } from './global/buttons';
import { ReactComponent as Camera } from '../assets/gallery/camera.svg';
import { ReactComponent as CheckboxPurple } from '../assets/gallery/checkbox-purple.svg';
import { ReactComponent as Download } from '../assets/gallery/download.svg';
import { ReactComponent as Timelapse } from '../assets/gallery/timelapse.svg';
import { ReactComponent as Delete } from '../assets/gallery/delete.svg';
import { ReactComponent as Cross } from '../assets/global/cross.svg';
import { DatePeriod } from './pages/gallery/datePeriod';
import { ImageModal } from './pages/gallery/imageModal';
import { TimelapseModal } from './pages/gallery/timelapseModal';
import { Modal } from './global/modals'
import { parseISO } from 'date-fns';

import CircularProgress from '@mui/material/CircularProgress';

const Gallery = () => {
  const { t } = useTranslation(['gallery', 'controls', 'global']);
  const { accessToken } = useAuth();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [timeInterval, setTimeInterval] = useState(t('timeInterval.weeks'));
  const [selectedImages, setSelectedImages] = useState([]);
  const [orderedSelectedImages, setOrderedSelectedImages] = useState([]);
  const [selectedImageObjects, setSelectedImageObjects] = useState([]);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [imageToDelete, setImageToDelete] = useState(null);
  //state to select a whole section
  
  //modals
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isTimelapseModalOpen, setIsTimelapseModalOpen] = useState(false);
  
  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(prevState => !prevState);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  const closeTimelapseModal = () => {
    setIsTimelapseModalOpen(false);
  };

  const itemsGallery = [
    { svg: '🗓', label: t('timeInterval.weeks') },
    { svg: '🗓', label: t('timeInterval.months') },
    { svg: '🗓', label: t('timeInterval.all_pictures') },
  ];

  const timeIntervalMapping = {
    [t('timeInterval.weeks')]: 'week',
    [t('timeInterval.months')]: 'month',
    [t('timeInterval.all_pictures')]: 'all',
  };

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setIsImageModalOpen(true);
  };

  const handleSelectionChange = (newSelectedImages) => {
    setSelectedImages(newSelectedImages);
    const newOrderedSelectedImages = [...newSelectedImages]
    .sort((a, b) => parseISO(a.date) - parseISO(b.date))
    .map(item => ({
        img: item.img,
        date: item.date
    }));

    setOrderedSelectedImages(newOrderedSelectedImages);

  };

  const handleSingleImageDeletion = () => {
    if (imageToDelete) {
      deletePicturesDevice(accessToken, [imageToDelete.date])
        .then(() => {
          const updatedImages = images.filter(img => img.date !== imageToDelete.date);
          setImages(updatedImages);
          setImageToDelete(null);
          toggleDeleteModal();
        })
        .catch((error) => {
          console.error('Failed to delete image', error);
        });
    }
  };
  const selectImageForDeletion = (image) => {
    setImageToDelete(image);
    toggleDeleteModal();
  };

  const handleDeleteImages = () => {
    const remainingImages = images.filter(image => 
      !selectedImages.some(selectedImage => selectedImage.date === image.date)
    );
  
    setImages(remainingImages);
    setSelectedImages([]);
    setSelectedImageObjects([]); 
  
    deletePicturesDevice(accessToken, selectedImages);
    toggleDeleteModal(); 
  };
  

  const toggleSelectMode = () => {
    if (isSelectMode) {
      setSelectedImages([]);
    }
    setIsSelectMode(!isSelectMode);
  };



  const handleDropdownChange = (selectedItem) => {
    const interval = timeIntervalMapping[selectedItem.label] || 'all';
    setTimeInterval(interval);
  };

  const getLatestImages = () => {
    getHistoricalImagesDevice(accessToken, 100)
      .then((result) => {
        setImages(result.images || []);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
        setIsLoading(false);
      });
  };
 
  useEffect(() => {
    getLatestImages();
  }, []);

  const takePicture = () => {
    takePictureDevice(accessToken);
  };

  return (
    <div className="gallery-wrapper flex flex-col h-full w-full">
      {isLoading || images.length > 0 ? (
        <>
        <div className="action-bar">
        {!isSelectMode ? (
          <>
            <Dropdown
              items={itemsGallery}
              onChange={handleDropdownChange}
              className="gallery-dropdown item-no-icon"
            />
            <div className='action-buttons'>
              <Button
                type="secondary"
                color="purple"
                iconFirst={<CheckboxPurple />}
                className="select-picture-btn gallery-btn"
                onClick={toggleSelectMode}
                label={t('buttons.select_pictures')}
              />
              <Button
                type="primary"
                color="purple"
                iconFirst={<Camera />}
                label={t('buttons.take_picture')}
                className="take-picture-btn gallery-btn"
                onClick={takePicture}
              />
            </div>
          </>
        ) : (
          <>
            <div className='flex gap-16 items-center justify-start'>
              <IconButton icon={<Cross/>}
                type='secondary'
                color='purple'
                onClick={toggleSelectMode}
                className='toggle-select-close-btn'
              />
              <h4>{t('buttons.numberSelected', { count: selectedImages.length })}</h4>
            </div>
            {selectedImages.length >= 1 && (
              <div className="action-buttons flex">
                <Button
                  type="primary"
                  color="purple"
                  iconFirst={<Download />}
                  label={t('buttons.download')}
                  className="download-btn gallery-btn"
                  onClick={() => downloadSelectedImages(selectedImages, images)} 
                />
               {selectedImages.length >= 2 && (
                  <Button
                    type="primary"
                    color="green"
                    iconFirst={<Timelapse />}
                    label={t('buttons.play_timelapse')}
                    className="timelapse-btn gallery-btn"
                    onClick={() => {
                      setCurrentImage(selectedImages[0]); 
                      setIsTimelapseModalOpen(true); 
                    }}
                  />
                )}
                <Button
                  type="primary"
                  color="red"
                  iconFirst={<Delete />}
                  label={t('buttons.delete')}
                  className="delete-btn gallery-btn"
                  onClick={toggleDeleteModal} 
                />
              </div>
            )}
          </>
        )}
        </div>
        {!isLoading && images.length > 0 ? (
          <DatePeriod
          images={images}
          timeInterval={timeInterval}
          isSelectMode={isSelectMode}
          onSelectionChange={handleSelectionChange}
          onImageClick={handleImageClick}
          />     
        ) : (
          <div className="loading-placeholder loading-placeholder-container">
            <CircularProgress />
            <h4>{t('loading', { ns: 'global' })}</h4>
          </div>
        )}
        </>
      ) : (
        <div className="flex flex-col text-center items-center justify-center loading-placeholder">
          <div className='flex flex-col items-center justify-center'>
            <h4>{t('farm_empty', { ns: 'controls' })}</h4>
            <Button type="primary" color="purple" iconFirst={<Camera/>} label="Take picture" />
          </div>
        </div>
      )}
      <Modal 
        isOpen={isDeleteModalOpen} 
        closeModal={toggleDeleteModal}
        heading={t('modals.deleteAmount', { count: selectedImages.length || (imageToDelete ? 1 : 0) })}
        subheading={t('modals.recover')}
        buttons
        customBtn2={<Button 
          type='primary' 
          color='red' 
          label={t('buttons.delete')} 
          onClick={selectedImages.length ? handleDeleteImages : handleSingleImageDeletion} 
        />}
      />
      {/* <h3 className="modal-heading">{t('modals.deleteAmount', { count: selectedImages.length || (imageToDelete ? 1 : 0) })}</h3>
      <div className="modal-paragraph flex-col space-between">
        {t('modals.recover')}
        <div className="modal-bottom">
          <Button type='secondary' color='purple' label={t('buttons.cancel')} onClick={toggleDeleteModal} />
          <Button 
            type='primary' 
            color='red' 
            label={t('buttons.delete')} 
            onClick={selectedImages.length ? handleDeleteImages : handleSingleImageDeletion} 
          />
        </div>
      </div> */}

    {isImageModalOpen && (
      <ImageModal
        currentImage={currentImage}
        images={images}
        setCurrentImage={setCurrentImage}
        onSelectImageForDeletion={selectImageForDeletion}
        closeImageModal={closeImageModal}
        isTimelapse={false}
      />
    )}
    {isTimelapseModalOpen && (
      <TimelapseModal
        images = {orderedSelectedImages}
        closeTimelapseModal={closeTimelapseModal}
      />
    )}
    </div>
  );
  
};

export default Gallery;
