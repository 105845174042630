import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import colorsReducer from './slices/colorsSlice';
import pumpsReducer from './slices/pumpsSlice';

const persistConfig = {
  key: 'root',
  storage
};

// Persisted reducers
const persistedColorsReducer = persistReducer(persistConfig, colorsReducer);
const persistedPumpsReducer = persistReducer(persistConfig, pumpsReducer);

const store = configureStore({
  reducer: {
    colors: persistedColorsReducer,
    pumps: persistedPumpsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PAUSE', 'persist/REGISTER', 'persist/FLUSH'],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
