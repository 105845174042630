import React, { useState, useEffect } from 'react';
import './imageModal.css';
import { downloadImage } from '../../functions/gallery/imageFunctions';
import Button from '../../global/buttons';
import { IconButton } from '../../global/buttons';
import { ReactComponent as Download } from '../../../assets/gallery/download.svg';
import { ReactComponent as Delete } from '../../../assets/gallery/delete.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/gallery/arrow-left.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/gallery/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../../assets/gallery/chevron-right.svg';
import { parseISO, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const ImageModal = ({ currentImage, setCurrentImage, images, onSelectImageForDeletion, closeImageModal }) => {
    const { t } = useTranslation('gallery');
    const { i18n } = useTranslation();

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const index = images.findIndex(image => image === currentImage);
        setCurrentIndex(index);
    }, [currentImage, images]);

    useEffect(() => {
        const index = images.findIndex(image => `data:image/jpeg;base64,${image.img}` === currentImage);
        if (index !== -1) {
            setCurrentIndex(index);
        }
    }, [currentImage, images]);

    const goToPrevious = () => {
        let newIndex = currentIndex - 1;
        if (newIndex < 0) {
            newIndex = images.length - 1; 
        }
        setCurrentImage(`data:image/jpeg;base64,${images[newIndex].img}`);
    };
    
    const goToNext = () => {
        let newIndex = currentIndex + 1;
        if (newIndex >= images.length) {
            newIndex = 0; 
        }
        setCurrentImage(`data:image/jpeg;base64,${images[newIndex].img}`);
    };

    const handleDeleteClick = () => {
        onSelectImageForDeletion(images[currentIndex]);
    };

    const formatDate = (dateString) => {
        const parsedDate = parseISO(dateString);
        return format(parsedDate, 'dd/MM/yyyy HH:mm:ss');
    };

    const handleContentClick = (event) => {
        if (event.currentTarget === event.target) {
            closeImageModal();
        }
    };

    return (
        <div className="image-modal">
            <div className='image-modal-navbar flex'>
              <div className='image-navbar-left flex'>
                <IconButton type="primary" color="dark" icon={<ArrowLeft/>} onClick={closeImageModal}/>
                <div className="navbar-image-date">{formatDate(images[currentIndex].date)}</div>
                </div>
                <div className="image-navbar-right flex">
                    <Button
                        type="primary"
                        color="dark"
                        iconFirst={<Download />}
                        label={t('buttons.download')}
                        className="download-btn gallery-btn"
                        onClick={() => downloadImage(images[currentIndex])} 
                    />
                    <Button
                    type="primary"
                    color="dark"
                    iconFirst={<Delete />}
                    label={t('buttons.delete')}
                    className="delete-btn gallery-btn"
                    onClick={handleDeleteClick} 
                    />
                </div>
              </div>
            <div className="image-modal-content" onClick={handleContentClick}>
                <img src={currentImage} alt={`Slide ${currentIndex}`} />
                <div className="button-group-wrapper">
                    <div className="button-group">
                        <IconButton type="primary" color="dark" icon={<ChevronLeft/>} onClick={goToNext}/>
                        <IconButton type="primary" color="dark" icon={<ChevronRight/>} onClick={goToPrevious}/>
                    </div>
                </div>
            </div>
        </div>
    );
};