import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

const Logout = () => {
  const { t } = useTranslation('global');
  return (
    <div className="loading-placeholder">
      <CircularProgress />
      <h4>{t('logout', { ns: 'global' })}</h4>
    </div>
  )
}

export default Logout;