import React, { useEffect, useState } from 'react';
import './modals.css';
import { BoxedIcon } from './boxedicon';
import ReactDOM from 'react-dom';
import Button, {IconButton} from './buttons.js';
import { ReactComponent as Cross } from '../../assets/global/cross.svg';
import { useTranslation } from 'react-i18next';

const Modal = ({ name, isOpen, closeModal, large, icon, heading, subheading, children, buttons, btnLabel, btnAction, customBtn1, customBtn2}) => {
  const [animate, setAnimate] = useState(false);
  const largeClass = large ? `modal-large`: '';
  const { t, i18n } = useTranslation('global');

  useEffect(() => {
    if (isOpen) {
      // add a slight delay before starting the animation
      setTimeout(() => setAnimate(true), 20);
    } else {
      setAnimate(false);
    }
  }, [isOpen]);

  if (!isOpen && !animate) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className='modal'>
      <div className={`modal-blanket ${animate ? 'animate' : ''}`} onClick={closeModal}></div>
      <div
        className={`modal-container ${largeClass} ${animate ? 'animate' : ''}`}
        onClick={e => e.stopPropagation()}
      >
        <IconButton type="secondary" color="purple" icon={<Cross/>} onClick={closeModal} className="modal-close"/>
        
        <div className='flex flex-col modal-content gap-32'>
          {icon && <BoxedIcon state="idle" icon={icon} />}
          <div className='flex flex-col'>
            <h3 className="modal-heading">{heading}</h3>
            {subheading && <p className='modal-subheading'>{subheading}</p>}
          </div>
          {children}
          {buttons &&
            <div className='action-buttons'>
              {!customBtn1 ?
                <Button type="secondary" color="purple" label={t('cancel')} onClick={closeModal}></Button>
                :
                customBtn1
              }
              {!customBtn2 ?
                <Button type="primary" color="green" label={btnLabel} onClick={btnAction}></Button>
                :
                customBtn2
              }
            </div>
          }
        </div>
      </div>
    </div>,
    document.body
  );
};

export { Modal };
