import './buttons.css';
import React from 'react';

const Button = ({ type, color, small, iconFirst, label, iconLast, onClick, className, customStyles }) => {

  const colorClass = color ? `btn-${color}` : '';
  const typeClass = type ? `btn-${type}` : '';
  const smallClass = small ? 'btn-small' : '';

  
  // Check if className includes 'btn-pumping' and set pseudoElementStyles if true
  let pseudoElementStyles = '';
  if (className && className.includes('btn-pumping')) {
    pseudoElementStyles = `
      .${className}::after { 
        animation: ${customStyles.animation};
        animation-fill-mode: forwards;
      }
    `;
  }

  return (
    <>
      <button className={`btn ${colorClass} ${typeClass} ${smallClass} ${className}`} onClick={onClick} style={customStyles}>
        <div className="btn-content">
          {iconFirst && <span className="btn-icon-first">{iconFirst}</span>}
          <span className="btn-label">{label}</span>
          {iconLast && <span className="btn-icon-last">{iconLast}</span>}
        </div>
      </button>
      <style>{pseudoElementStyles}</style>
    </>
  );
}

const IconButton = ({type, color, small, icon, onClick, className, customStyles}) => {

  const colorClass = color ? `btn-${color}` : '';
  const typeClass = type ? `btn-${type}` : '';
  const smallClass = small ? 'btn-small' : '';

  return (
      <button className={`btn icon-btn ${colorClass} ${typeClass} ${smallClass} ${className}`} onClick={onClick} style={customStyles}>
        <div className="btn-content">
          {icon && <span className="btn-icon-first">{icon}</span>}
        </div>
      </button>
  );

}

export default Button;
export { IconButton };
