import React, { useState } from 'react';
import { ReactComponent as IconCorrect } from '../../../assets/global/check-fill-small.svg';
import { ReactComponent as IconIncorrect } from '../../../assets/global/cross-fill-small.svg';


function QuizQuestion({ questionData }) {
  const [checkedItems, setCheckedItems] = useState(
    new Array(questionData.question_options.questions.options.length).fill(false)
  );

  const [revealed, setRevealed] = useState(false);

  const handleCheckboxChange = (index, option) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);

    setRevealed(true);
  };
  
  return (
    <div className="question-container">
      <p className="question-title bold">{questionData.question_title}</p>
      <ul className="options-list">
        {questionData.question_options.questions.options.map((option, index) => (
          <li className="question-item" key={index}>
            <input
              className="question-checkbox"
              type="checkbox"
              value={option.value}
              id={`option-${index}`}
              style={{ display: 'none' }}
            />
            <label className='question-value' htmlFor={`option-${index}`} onClick={() => handleCheckboxChange(index, option)}>
              {revealed ? (
                option.isCorrect ?
                  <div className='question-checkbox correct'><IconCorrect /></div>
                  :
                  <div className='question-checkbox incorrect'><IconIncorrect /></div>
              ) : (
                <div className='question-checkbox'/>
              )}
              <p>{option.value}</p>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default QuizQuestion;

