import "./learning.css";
import React, { useState, useEffect } from 'react';
import CourseBlock from './pages/learning/courseBlock';
import BreadCrumb from './pages/learning/breadCrumb';
import { useTranslation } from "react-i18next";

const Learning = () => {
  const { t, i18n } = useTranslation('learning');
  const mainLocale = i18n.language.split('-')[0];
  // State to store courses data
  const [courses, setCourses] = useState([]);
  
  useEffect(() => {
    const fetchMinifiedCourse = async () => {
      try {
        const response = await fetch(
          `https://cdn.tinyfar.ms/api/minified-courses?locale=${mainLocale}&populate=deep`,
          
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Server responded with ${response.status}: ${errorData.message || response.statusText}`);
        }
        const data = await response.json();
        // Update courses state
        setCourses(data.data);

      } catch (err) {
        console.error(`Error fetching courses: ${err.message}`);
      }
    };

    fetchMinifiedCourse();
  }, []);

  return (
    <div className="learning justify-top items-center w-full">   
      <div className="action-bar">
        <BreadCrumb name={t('all_lessons')}/>
      </div>

      {courses.map(course => (
        <CourseBlock 
          key={course.id}
          icon={course.attributes.icon} 
          courseName={course.attributes.name}
          courseTitle={course.attributes.title}
          courseDescription={course.attributes.description}
          courseLink={course.attributes.link}
        />
      ))}

    </div>
  );
};

export default Learning;
