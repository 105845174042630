import './controlHeader.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../global/buttons.js';

const ControlHeader = ({ text, label, link }) => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(link);
    }

    return (
        <div className="flex justify-between items-center w-full">
            <div className="control-header-text">
                {text}
            </div>
            <div className="flex-shrink-0 control-header-btn">
                {/* <Button type='secondary' color='purple' label={label} onClick={handleButtonClick}/> */}
            </div>
        </div>
    );
};

export default ControlHeader;
