import React from 'react';

const Mobile = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-xl shadow-md text-center">
        <h1 className="text-xl font-semibold mb-4">Unable to be shown on mobile 📱</h1>
        <p className="text-gray-600">Please visit this application on either iPad or Desktop 💻</p>
      </div>
    </div>
  );
};

export default Mobile;
