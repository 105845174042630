import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import useAuth  from '../../../hooks/useAuth'; 
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ element: Component, ...props }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const token = localStorage.getItem('access_token');
  
  if (!isAuthenticated && !token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (isMobileOnly) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return React.cloneElement(Component, props);
};

export default ProtectedRoute;
