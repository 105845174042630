import React from 'react';
import Blockly from 'blockly';
import { XMLBuilder, XMLParser } from "fast-xml-parser";
 

// sending commands with our format
export const returnJsonCommand = (workspace) => {
  const serializer = new Blockly.serialization.blocks.BlockSerializer();
  const state = serializer.save(workspace);
  const blocks = state?.blocks;

  if (blocks) {
    const strippedBlocks = stripBlocklyJson(blocks);
    return strippedBlocks;
    } else {
    const strippedBlocks = {};
    return strippedBlocks;
  }
};


export function stripBlocklyJson(json) {
    if (Array.isArray(json)) {
      return json.map(stripBlocklyJson);
    } else if (json && typeof json === 'object') {
      const { id, x, y, ...newJson } = json;
      for (const key in newJson) {
        newJson[key] = stripBlocklyJson(newJson[key]);
      }
      if (newJson.block && typeof newJson.block === 'object') {
        newJson.block = stripBlocklyJson(newJson.block);
      }
      return newJson;
    }
    return json;
  }

// utility functions for json to xml 
export const xmlWorkspaceToJson = (workspace) => {
  const xmlDom = Blockly.Xml.workspaceToDom(workspace);
  const xmlString = new XMLSerializer().serializeToString(xmlDom);
  // Options to handle XML attributes
  const parserOptions = {
    attributeNamePrefix: "_",
    attrNodeName: "_attributes",
    ignoreAttributes: false,
  };
  
  const parser = new XMLParser(parserOptions);
  const json = parser.parse(xmlString);
  return json;
};

export const jsonToBlocklyWorkspace = (json, workspace) => {
  // Options to handle XML attributes
  const jsonString = json.replace(/'/g, '"');
  const jsonObj = JSON.parse(jsonString);
  const builderOptions = {
    attributeNamePrefix: "_",
    attrNodeName: "_attributes",
    ignoreAttributes: false,
  };
  const builder = new XMLBuilder(builderOptions);
  const xmlString = builder.build(jsonObj);
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "text/xml");
  Blockly.Xml.domToWorkspace(xmlDoc.documentElement, workspace);
};

export const workspaceToCurrentWorkspace = (sourceWorkspace, targetWorkspace) => {
  const xml = Blockly.Xml.workspaceToDom(sourceWorkspace);

  Blockly.Xml.domToWorkspace(xml, targetWorkspace);
};


export function createBlocklySvgFromWorkspace(workspace, simple = false) {
  if (workspace && workspace.svgBlockCanvas_) {
    const svg = workspace.svgBlockCanvas_.cloneNode(true);

    // Create a temporary SVG element to calculate the bounding box
    const tempSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    tempSvg.appendChild(svg);
    document.body.appendChild(tempSvg);

    // Get the bounding box of the SVG content
    const bbox = svg.getBBox();

    if (simple) {
      const iconGroup = svg.querySelectorAll(".blocklyIconGroup");
      const textGroup = svg.querySelectorAll(".blocklyEditableText");
      const rectGroup = svg.querySelectorAll(".blocklyFieldRect");
      const outlinePath = svg.querySelectorAll(".blocklyOutlinePath");
      iconGroup.forEach((icon) => icon.remove());
      textGroup.forEach((text) => text.remove());
      rectGroup.forEach((rect) => rect.remove());
      outlinePath.forEach((path) => path.remove());
    }

    // Remove the temporary SVG element from the document
    document.body.removeChild(tempSvg);

    // Construct the viewBox attribute based on the bounding box
    const viewBoxValue = `0 0 ${bbox.width} ${bbox.height}`;

    const svgProps = {
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "xmlnsXlink": "http://www.w3.org/1999/xlink",
      viewBox: viewBoxValue,
      preserveAspectRatio: "xMidYMid meet"
    };

    // Serialize the SVG to a string
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);

    return (
      <svg {...svgProps} dangerouslySetInnerHTML={{ __html: svgString }} />
    );
  } else {
    // Return null or a placeholder image if the workspace or SVG canvas is not available
    return null;
  }
}

export function jsonToBlocklySvg(json, workspaceConfig, simple=false) {
  const tempDiv = document.createElement('blockly-hidden');
  tempDiv.style.display = 'none';
  document.body.appendChild(tempDiv);

  const tempWorkspace = Blockly.inject(tempDiv, workspaceConfig);

  const programWorkspace= jsonToBlocklyWorkspace(json, tempWorkspace);
  let svgData;
  if (simple) {
    svgData = createBlocklySvgFromWorkspace(tempWorkspace, simple);
  } else {
    svgData = createBlocklySvgFromWorkspace(tempWorkspace);
  }
  

  tempWorkspace.dispose();
  document.body.removeChild(tempDiv);
  return { svgData, programWorkspace };
}

export const downloadJson = (jsonObject, fileName) => {
  const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(jsonObject, null, 2));
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", fileName);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const removeTextNodes = (element) => {
  if (element.nodeType === Node.TEXT_NODE) {
    element.parentNode.removeChild(element);
  } else {
    let i = element.childNodes.length;
    while (i--) {
      removeTextNodes(element.childNodes[i]);
    }
  }
};

export function handleScrollbarVisibility(workspace) {
  let scrollbarTimeout;

  workspace.addChangeListener(function(event) {
    if (event.type === 'viewport_change') {
        var scrollbars = document.querySelectorAll('.blocklyMainWorkspaceScrollbar');
        for (var i = 0; i < scrollbars.length; i++) {
            scrollbars[i].style.visibility = 'visible';
            scrollbars[i].style.opacity = 0.7;
            scrollbars[i].style.transition = 'opacity 0s';
            scrollbars[i].style.animation = '';  
        }

        if (scrollbarTimeout) {
          clearTimeout(scrollbarTimeout);
        }

        scrollbarTimeout = setTimeout(function() {
            for (var i = 0; i < scrollbars.length; i++) {
                scrollbars[i].style.animation = 'fadeout 1s forwards';
                scrollbars[i].addEventListener('animationend', function() {
                  this.style.animation = '';
                  this.style.visibility = 'hidden';
                }, { once: true });  
            }
        }, 3000);
    }
  });
}
// Blockly block helper functions

export function hoursValidator(text) {
  const num = parseInt(text, 10);
  if (num >= 0 && num <= 23) {
    return num.toString().padStart(2, '0');
  } else {
    return null;
  }
}

export function minutesValidator(text) {
  const num = parseInt(text, 10);
  if (num >= 0 && num <= 59) {
    return num.toString().padStart(2, '0');
  } else {
    return null;
  }
}

export function getTextColorBasedOnBackground(backgroundColor) {
 
  if (backgroundColor.indexOf('#') === 0) {
    backgroundColor = hexToRGB(backgroundColor);
  }

  const matches = backgroundColor.match(/(\d+)/g);
  if (!matches) return '#000000';  

  const [r, g, b] = matches.map(Number);

  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  return luminance < 128 ? '#FFFFFF' : '#000000';  
}

function hexToRGB(hex) {
  let bigint = parseInt(hex.slice(1), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  
  return `rgb(${r},${g},${b})`;
}




//Old 



// export const saveAsSvg = (workspaceRef) => {

//   const canvas = workspaceRef.current.svgBlockCanvas_.cloneNode(true);
//       if (!canvas.children[0]) {
//         console.error("Couldn't find Blockly canvas.");
//         return;
//       }
//       const clonedCanvas = canvas.cloneNode(true);
//       clonedCanvas.removeAttribute("transform");
      
//       // removeTextNodes(clonedCanvas);
  
//       const iconGroup = clonedCanvas.querySelectorAll(".blocklyIconGroup");
//       const textGroup = clonedCanvas.querySelectorAll(".blocklyEditableText");
//       const rectGroup = clonedCanvas.querySelectorAll(".blocklyFieldRect");
//       const outlinePath = clonedCanvas.querySelectorAll(".blocklyOutlinePath");
//       iconGroup.forEach((icon) => icon.remove());
//       textGroup.forEach((text) => text.remove());
//       rectGroup.forEach((rect) => rect.remove());
//       outlinePath.forEach((path) => path.remove());
  
//       const css = `<defs><style type="text/css" xmlns="http://www.w3.org/1999/xhtml"><![CDATA[
//         /* Add your Blockly CSS here or load it externally */
//       ]]></style></defs>`;
//       const content = new XMLSerializer().serializeToString(clonedCanvas);
    
//       const svgString = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 1000 1000" preserveAspectRatio="xMidYMid meet">' +
//       css + content + '</svg>';
  
//     const previousWorkspacesWrapper = document.querySelector('.previous_workspaces_wrapper');
    
//     if (previousWorkspacesWrapper) {
//       const newWorkspaceWrapper = document.createElement('div');
//       newWorkspaceWrapper.className = 'previous_workspace_wrapper';
  
//       const newPreviousWorkspace = document.createElement('div');
//       newPreviousWorkspace.className = 'previous_workspace';
  
//       const newWorkspaceTime = document.createElement('div');
//       newWorkspaceTime.className = 'previous_workspace_time';
  
//       // Get the current time and format it
//       const currentTime = new Date();
//       const formattedTime = currentTime.toLocaleTimeString();
//       const formattedDate = currentTime.toLocaleDateString();
  
//       // Combine the formatted time and date with a separator, e.g., a comma and a space
//       const formattedDateTime = `${formattedTime}, ${formattedDate}`;
  
//       // Set the innerHTML of the previous_workspace_time div
//       newWorkspaceTime.innerHTML = formattedDateTime;
  
//       const svgElement = document.createElement('div');
//       svgElement.className = 'saved-svg';
//       svgElement.innerHTML = svgString;
  
//       newPreviousWorkspace.appendChild(svgElement);
//       newWorkspaceWrapper.appendChild(newPreviousWorkspace);
//       newWorkspaceWrapper.appendChild(newWorkspaceTime);
  
//       previousWorkspacesWrapper.appendChild(newWorkspaceWrapper);
//     }
// };

  