import React from 'react';
import './app.css';
import { isMobileOnly } from 'react-device-detect';
import { Routes, Route, useLocation } from 'react-router-dom';
import ProtectedRoute from './components/functions/auth/protectedRoute';
import Sidebar from './components/sidebar';
import Controls from './components/controls';
import Gallery from './components/gallery';
import Login from './components/login';
import Learning from './components/learning';
import Course from './components/course';
import {Activity, Quiz} from './components/activity';
import Programming from './components/programming';
import Settings from './components/settings';
import Logout from './components/logout';
import Mobile from './components/mobile'

// redux 
import { Provider } from 'react-redux';  
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store'

function App() {
  const location = useLocation();
  const isLogin = location.pathname.startsWith('/login');
  if (isMobileOnly) {
    return <Mobile />;
  }

  return (
    <div className="main flex flex-row h-screen">
     {!isLogin && (
          <Sidebar />
      )}
      <div className="content flex-grow">
        <Routes>
          {/* Protected routes */}
          <Route path="/" element={<ProtectedRoute element={ 
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
            <Controls /></PersistGate>
          </Provider>} 
          />}/>
          <Route path="/programming" element={<ProtectedRoute element={<Programming />} />} />
          {/* <Route path="/analytics" element={<ProtectedRoute element={<Analytics />} />} /> */}
          <Route path="/gallery" element={<ProtectedRoute element={<Gallery />} />} />
          <Route path="/learning" element={<ProtectedRoute element={<Learning />} />} />
          <Route path="/learning/lesson/:number" element={<ProtectedRoute element={<Course />} />} />
          <Route path="/learning/activity/:number" element={<ProtectedRoute element={<Activity />} />} />
          <Route path="/learning/quiz/:number" element={<ProtectedRoute element={<Quiz />} />} />
          <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />
          {/* Non protected routes */}
          <Route path="/logout" element={<Logout />}/>
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
