import config from '../../../config';
import io from 'socket.io-client';

const sendInstruction = (accessToken, instruction, _value) => {
  const url = `${config.apiEndpoint}/devices/instruction`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      instruction: instruction,
      value: _value,
    }),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to send command: ${response.statusText}`);
      }
      return response;
    })
    .catch(error => {
      console.error('Error:', error);
      throw error; 
    });
};


const saveSensorValueToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify({value, expiry: Date.now() + 60 * 60 * 1000}));
};

const setupSocket = (accessToken, deviceId, handlers = {}) => {
  const MAX_RETRIES = 10;
  let retries = 0;
  const socket = io(config.apiEndpoint, {
    extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
      },
  });
  const attemptReconnection = () => {
      if (retries < MAX_RETRIES) {
          console.log(`Reconnection attempt #${retries + 1}`);
          retries += 1;
          socket.connect();
      } else {
          console.error('Max reconnection attempts reached.');
      }
  };

  socket.on("connect", () => {
    console.log("Connected to WebSocket server!");
    socket.emit("subscribe_to_sensor_data");
    socket.emit("subscribe_to_modular_info");
  });

  socket.on('connect_error', (error) => {
      console.log('Connection Error: ', error);
      setTimeout(attemptReconnection, 1000); 
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from websocket server!");
  });

  socket.on('error', (error) => {
      console.log('Error: ', error);
  });

  socket.on(`modular_data_${deviceId}`, (data) => {
    const { sensor_type, value } = data;
    if (handlers[sensor_type]) {
        handlers[sensor_type](value);
        saveSensorValueToSessionStorage(sensor_type, value);
    }
});

socket.on(`modular_info_${deviceId}`, (data) => {
  const { sensor_type, value } = data;
  if (handlers[sensor_type]) {
      handlers[sensor_type](value);
      saveSensorValueToSessionStorage(sensor_type, value);
  }
});
  return socket;
};


export function getInitialSensorValue(key) {
  const item = JSON.parse(sessionStorage.getItem(key));
  
  if (item && item.expiry > Date.now()) {
    return item.value;
  } else {
    return null;
  }
}


// camera endpoints

const getHistoricalImagesDevice = (accessToken, amount) => {
  const url = `${config.apiEndpoint}/devices/get_picture?amount=${amount}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to get pictures: ${response.statusText}`);
      }
      return response.json();
    })
    .then(data => data) 
    .catch(error => {
      console.error('Error:', error);
      return []; 
    });
};



const getLatestImageDate = (accessToken) => {
  const url = `${config.apiEndpoint}/devices/get_picture?latest=true`;

  fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to get pictures: ${response.statusText}`);
      }
      return response.json();
    })
    .then(data => data) 
    .catch(error => {
      console.error('Error:', error);
      return []; 
    });
};

const takePictureDevice = (accessToken) => {
  return sendInstruction('picture', '1', accessToken);
};

export {sendInstruction, setupSocket, getLatestImageDate, getHistoricalImagesDevice, takePictureDevice}