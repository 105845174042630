import Button from '../../global/buttons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

export const HeaderElements = ({ page, number }) => {
    const { t, i18n } = useTranslation('learning');

    const lessonLink = page === 'lesson' ? '#' : `/learning/lesson/${number}`;
    const quizLink = page === 'quiz' ? '#' : `/learning/quiz/${number}`;
    const activityLink = page === 'activity' ? '#' : `/learning/activity/${number}`;

    const getButtonType = (buttonPage) => page === buttonPage ? 'primary' : 'secondary';

    return (
        <div className="button-group">
            <Link to={lessonLink}>
                <Button type={getButtonType('lesson')} color="purple" iconFirst="📖" label={t('text')} className="text-button"/>
            </Link>
            <Link to={quizLink}>
                <Button type={getButtonType('quiz')} color="purple" iconFirst="🤓" label={t('quiz')} className="quiz-button"/>
            </Link>
            <Link to={activityLink}>
                <Button type={getButtonType('activity')} color="purple" iconFirst="🧪" label={t('activity')} className="activity-button"/>
            </Link>                            
        </div>
    );
}
