  import './running.css';
  import React, {useState} from 'react';
  import {Modal} from '../../global/modals'
  import Button from '../../global/buttons'
  import InfoToast from '../../global/toasts'
  import { jsonToBlocklyWorkspace } from '../../functions/blockly/utils';
  import { useTranslation } from 'react-i18next';

  function ProgramRunning({ programSvgComponent, programRunningJSON, currentWorkspace, onStopProgram, isProgramRunning}) {
    const { t } = useTranslation('programming');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const animationClass = isProgramRunning ? 'is-running' : 'is-stopped';

    const handleViewClick = () => {
      setIsModalOpen(true); 
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const duplicateToWorkspace = () => {
      jsonToBlocklyWorkspace(programRunningJSON, currentWorkspace)
    };

    const handleDuplicateAndClose = () => {
      duplicateToWorkspace();
      handleCloseModal();
    };

    function stopProgram() {
      onStopProgram();
      setIsModalOpen(false);
    };

    console.log(programSvgComponent);

    return (
      <>
      <InfoToast
        label={t('toasts.programRunning.label')}
        color="green"
        buttons={[
          { label: t('toasts.programRunning.btn1'), action: handleViewClick },
          { label: t('toasts.programRunning.btn2'), action: handleDuplicateAndClose },
          { label: t('toasts.programRunning.btn3'), color: 'red', action: stopProgram }
        ]}
        preview={programSvgComponent}
        classes={animationClass}
      >
        <Modal
          large
          heading={t('modalTitle')}
          subheading={t('modalParagraph')}
          buttons
          customBtn1={<Button type='primary' color='green' label={t('duplicateOnWorkspace')} onClick={handleDuplicateAndClose}/>}
          customBtn2={<Button type='primary' color='red' label={t('stopProgram')} onClick={stopProgram}/>}
          isOpen={isModalOpen}
          closeModal={handleCloseModal}>
            <div className="program-running-preview">{programSvgComponent}</div>
        </Modal>
      </InfoToast>
      </>        
    );
  }

  export default ProgramRunning;
