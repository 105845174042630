import './breadCrumb.css';
import React from 'react';

const BreadCrumb = ({ name }) => {
    

    return (
        <div className="breadcrumb-container">
            {name}
        </div>
    );
};

export default BreadCrumb;
