import './programming.css';
import React, { useEffect, useRef, useState } from 'react';
import Blockly from 'blockly';
import useAuth from '../hooks/useAuth'
import { localizedToolboxConfiguration, workspaceConfiguration } from '../blockly/blocklyConfig';
import {  handleScrollbarVisibility, 
          xmlWorkspaceToJson, 
          jsonToBlocklyWorkspace, 
          jsonToBlocklySvg,
          returnJsonCommand} from './functions/blockly/utils'
import {  saveBlocklyProgram, 
          sendJsonCommandDevice, 
          getProgramsJSON, 
          sendStopCommandDevice } from './functions/blockly/blocklyEndpointFunctions'
import {setupBlocks} from '../blockly/customBlocks'
import ProgramRunning from './pages/programming/running';
import { ProgramCode } from './pages/programming/histProgram';
import {Modal} from './global/modals'
import Button from './global/buttons'
import { useTranslation } from 'react-i18next';
import * as en from 'blockly/msg/en';
import * as da from 'blockly/msg/da';
 

function Programming() {
  const { t, i18n } = useTranslation(['programming', 'global']);
  const {accessToken: accessToken, loading} = useAuth();
  const blocklyDiv = useRef(null);
  const programNameInputRef = useRef(null);
  const [workspace, setWorkspace] = useState(null); 
  const [programRunningJSON, setProgramRunningJSON] = useState(null);
  const [svgData, setSvgData] = useState(null);
  const [isProgramRunning, setIsProgramRunning] = useState(false);
  const [allHistPrograms, setAllHistPrograms] = useState(null);

  const initializeCustomBlocks = (t) => {
    setupBlocks(Blockly, t);
  }
  // modals 
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  // save modal
  const handleSaveClick = () => {
    setIsSaveModalOpen(true); 
  };

  const handleCloseSaveModal = () => {
    setIsSaveModalOpen(false);
  };

  const [isOpenModalOpen, setIsOpenModalOpen] = useState(false);
  // open modal (historical code)
  const handleOpenClick = () => {
    setIsOpenModalOpen(true); 
  };

  const handleCloseOpenModal = () => {
    setIsOpenModalOpen(false);
  };

  const handleDuplicateProgram = () => {

    jsonToBlocklyWorkspace(currentlySelectedProgramJson, workspace);
    handleCloseOpenModal();
  };
  
  const [currentlySelectedProgramId, setCurrentlySelectedProgramId] = useState(null);
  const [currentlySelectedProgramJson, setCurrentlySelectedProgramJson] = useState(null);

  const handleSelectProgram = (selectedProgramId, selectedProgramJSON) => {
    if (currentlySelectedProgramId === selectedProgramId) {
      setCurrentlySelectedProgramId(null); 
      setCurrentlySelectedProgramJson(null); 
    } else {
      setCurrentlySelectedProgramId(selectedProgramId); 
      setCurrentlySelectedProgramJson(selectedProgramJSON); 
    }
  };

  async function sendCommand() {
    const currentProgramJson = xmlWorkspaceToJson(workspace);
    const jsonCommand = returnJsonCommand(workspace);
    console.log(jsonCommand);
    sendJsonCommandDevice(jsonCommand, currentProgramJson,t('autosave'),accessToken, true);
        
    setTimeout(async () => {
      try {
        const newProgramRunningJSON = await getProgramsJSON(accessToken, 0, true);
        const allProgramsJSON = await(getProgramsJSON(accessToken,18,false));
        if(newProgramRunningJSON){
          setProgramRunningJSON(newProgramRunningJSON);
          setAllHistPrograms(allProgramsJSON);
          setIsProgramRunning(true);
        }else{
          setIsProgramRunning(false);
        }
      } catch (error) {
        console.error('There was a problem fetching the new program:', error);
      }
    }, 1000); 
  }

  function stopRunningProgram() {

    sendStopCommandDevice(accessToken);
    setIsProgramRunning(false);
    
  }

  async function handleSaveProgram() {
    const currentProgramJson = xmlWorkspaceToJson(workspace);
    const programName = programNameInputRef.current.value;
  
    await saveBlocklyProgram(programName, currentProgramJson, accessToken, false, false);
  
    const allProgramsJSON = await getProgramsJSON(accessToken, 18, false);
  
    setAllHistPrograms(allProgramsJSON);
    
    handleCloseSaveModal();
  }
  
  const fetchPrograms = async () => {
    try {
      const newProgramRunningJSON = await getProgramsJSON(accessToken, 0, true);
      setProgramRunningJSON(newProgramRunningJSON);
      const allPrograms = await getProgramsJSON(accessToken, 18, false);
      setAllHistPrograms(allPrograms);

      if(newProgramRunningJSON){
        setIsProgramRunning(true);
      }
      else{
        setIsProgramRunning(false);
      }
    } catch (error) {
      console.error('There was a problem fetching the programs:', error);
    }
  };

  useEffect(() => {
    if (programRunningJSON) {
      console.log("program running json:", programRunningJSON);
      const { svgData: newSvgData, programWorkspace: newProgramWorkspace } = jsonToBlocklySvg(programRunningJSON, workspaceConfiguration, false);
      setSvgData(newSvgData);
    }
  }, [programRunningJSON]);

  useEffect(() => {
    initializeCustomBlocks(t);
    fetchPrograms();  
  }, []);

  

  useEffect(() => {
    // blockly workspace configuration and initial state
    const localizedToolbox = localizedToolboxConfiguration(t);
    const workspaceRef = Blockly.inject(blocklyDiv.current, {
      ...workspaceConfiguration,
      toolbox: localizedToolbox
    });

    const workspaceChangeHandler = () => {
      try {
        const currentWorkspaceJson = xmlWorkspaceToJson(workspaceRef);
        if (currentWorkspaceJson) {
          localStorage.setItem('blocklyWorkspace', JSON.stringify(currentWorkspaceJson));
          console.log("workspace changed, saving");
        }
      } catch (error) {
        console.error("An error occurred while saving the workspace:", error);
      }
    };

    workspaceRef.addChangeListener(workspaceChangeHandler);

    try {
      const savedWorkspaceJson = localStorage.getItem('blocklyWorkspace');
      if (savedWorkspaceJson) {
        jsonToBlocklyWorkspace(savedWorkspaceJson, workspaceRef);
      }
    } catch (error) {
      console.error("An error occurred while loading the workspace:", error);
    }
    

    Blockly.Scrollbar.scrollbarThickness = 18; 
    let blocklyLanguage;
    switch (i18n.language) {
      case 'en':
        blocklyLanguage = en;
        break;
      case 'da':
        blocklyLanguage = da;
        break;
      default:
        blocklyLanguage = en;  
    }

    Blockly.setLocale(blocklyLanguage);   
    workspaceRef.scrollCenter();
    
    handleScrollbarVisibility(workspaceRef);
    setWorkspace(workspaceRef); 

    console.log(svgData);
    
    // Dispose of workspace when we're done
    return () => {
      workspaceRef.dispose();
      workspaceRef.removeChangeListener(workspaceChangeHandler);
    };
  }, 
  []);
  
 
return (
  <div className="flex flex-col h-full programming-wrapper">
    <div className="action-bar">
      <div className="action-buttons">
        <div className="button-group">
          <Button type="secondary" color="purple" iconFirst="📂" label={t('buttons.open')} onClick={handleOpenClick}/>
          <Button type="secondary" color="purple" iconFirst="💾" label={t('buttons.save')} onClick={handleSaveClick}/>
        </div>
        <div className="button-group">
          <Button type="secondary" color="purple" iconFirst="↩️" label={t('buttons.undo')} onClick={() => workspace.undo(false)} />
          <Button type="secondary" color="purple" iconFirst="↪️" label={t('buttons.redo')} onClick={() => workspace.undo(true)} />
          <Button type="secondary" color="purple" iconFirst="🗑" label={t('buttons.clear')} onClick={() => workspace.clear()} />
        </div>
      </div>
      <Button
            type="primary"
            color="green"
            iconFirst="⬆️"
            label={t('buttons.runInTinyfarm')}          
            className="run-tinyfarm-btn"
            onClick={sendCommand} 
      />    
    </div> 
    <div className="relative flex-grow blockly-workspace-container">
      <div ref={blocklyDiv} id="blockly-div"/>
    </div>
    <Modal
      isOpen={isSaveModalOpen}
      closeModal={handleCloseSaveModal}
      heading={t('modals.saveProgram.heading')}
      buttons
      btnLabel={t('modals.saveProgram.save')}
      btnAction={handleSaveProgram}
    >
      <div className="flex flex-col gap-8">
        <div className="program-input-name">{t('modals.saveProgram.programName')}</div>            
        <input ref={programNameInputRef} className="program-save-input" placeholder={t('modals.saveProgram.placeholder')}></input>
      </div>
    </Modal>

    <Modal
      large
      isOpen={isOpenModalOpen}
      closeModal={handleCloseOpenModal}
      heading={t('modals.openProgram.heading')}
      buttons
      btnLabel={t('modals.openProgram.open')}
      btnAction={handleDuplicateProgram}
    >
        <div className='programs-wrapper'>
          <div className="programs">
            {allHistPrograms ? (
              allHistPrograms && allHistPrograms.map((program, index) => (
                <ProgramCode  
                  key={index}
                  programId={index}
                  program={program} 
                  currentlySelectedProgramId={currentlySelectedProgramId}
                  handleSelectProgram={handleSelectProgram}
                />
              ))
            ) : (
              <div className="flex flex-col text-center items-center justify-center loading-placeholder">
                <div className='flex flex-col items-center justify-center'>
                  <h4>{t('empty')}</h4>
                </div>
              </div>
            )
            }
          </div>
        </div>
    </Modal>
    {isProgramRunning && (
      <ProgramRunning 
      isProgramRunning={isProgramRunning}
      programSvgComponent={svgData} 
      programRunningJSON={programRunningJSON}
      currentWorkspace={workspace}
      onStopProgram={stopRunningProgram}/>
      )}
  </div>
);
}

export default Programming;
