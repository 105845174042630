import React, { useState, useEffect, useRef } from 'react';
import './timelapseModal.css'
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { enUS, da } from 'date-fns/locale';
import Button from '../../global/buttons';
import { IconButton } from '../../global/buttons';
import { ReactComponent as Download } from '../../../assets/gallery/download.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/gallery/arrow-left.svg';
import { ReactComponent as Timelapse } from '../../../assets/gallery/timelapseButton.svg';
import { ReactComponent as ProgressHandle } from '../../../assets/gallery/progress-handle.svg';
import { downloadTimelapse } from '../../functions/gallery/imageFunctions';

const ProgressBar = ({ currentIndex, totalImages, onHandleChange }) => {
    const [isDragging, setIsDragging] = useState(false);
    const progressBarRef = useRef(null);

    const updateIndex = (clientX) => {
        const rect = progressBarRef.current.getBoundingClientRect();
        const newProgress = Math.max(0, Math.min(1, (clientX - rect.left) / rect.width));
        // Adjust the logic to snap to the middle of a segment
        const segmentLength = 1 / totalImages;
        const newIndex = Math.round((newProgress - (segmentLength / 2)) * (totalImages - 1));
        onHandleChange(Math.max(0, newIndex));
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        updateIndex(e.clientX);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            updateIndex(e.clientX);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);
    

    return (
        <div className="progress-bar" ref={progressBarRef} onClick={(e) => updateIndex(e.clientX)}>
            {Array.from({ length: totalImages }, (_, index) => (
                index < totalImages - 1 && ( // Render all segments except the last one
                    <div key={index} className={`progress-segment ${index < currentIndex ? 'filled' : ''}`}></div>
                )
            ))}
            <ProgressHandle
                className={`progress-handle ${isDragging ? 'dragging' : ''}`}
                style={{ left: `${(currentIndex / (totalImages - 1)) * 100}%` }}
                onMouseDown={handleMouseDown}
            />
        </div>
    );
};

export const TimelapseModal = ({images, closeTimelapseModal }) => {
    const { t } = useTranslation('gallery');
    const { i18n } = useTranslation();
    const dateLocale = i18n.language === 'da' ? da : enUS;
    
    const [currentIndex, setCurrentIndex] = useState(0);

    const formatDate = (dateString) => {
        const parsedDate = parseISO(dateString);
        return format(parsedDate, 'dd/MM/yyyy HH:mm:ss', { locale: dateLocale });
    }

    const playTimelapse = () => {
        let startTime = null;
        const duration = 300 * images.length; 
    
        const animate = (time) => {
            if (startTime === null) startTime = time;
            const timeElapsed = time - startTime;
            const newIndex = Math.min(Math.floor((timeElapsed / duration) * images.length), images.length - 1);
    
            setCurrentIndex(newIndex);
    
            if (timeElapsed < duration) {
                requestAnimationFrame(animate);
            }
        };
    
        requestAnimationFrame(animate);
    };

    const downloadTimelapseFile = () => {
        downloadTimelapse(images);
    };

    const handleProgressChange = (newIndex) => {
        setCurrentIndex(newIndex);
    };

    return (
        <div className="timelapse-modal">
            <div className='timelapse-modal-navbar flex'>
                <div className='navbar-left flex'>
                    <IconButton type="primary" color="dark" icon={<ArrowLeft/>} onClick={closeTimelapseModal}/>
                    <div className="navbar-image-date">{formatDate(images[currentIndex].date)}</div>
                </div>
                <div className="navbar-right flex">
                    <Button
                        type="primary"
                        color="dark"
                        iconFirst={<Download />}
                        label={t('buttons.download')}
                        className="download-btn gallery-btn"
                        onClick={downloadTimelapseFile} 
                    />
                </div>
            </div>
            <div className="timelapse-modal-content">
                <img src={`data:image/jpeg;base64,${images[currentIndex].img}`} alt={`Slide ${currentIndex}`} />
                <div className="button-group-wrapper">
                    <div className="button-group">
                        <Timelapse onClick={playTimelapse} className='timelapse-button'/>
                        <ProgressBar currentIndex={currentIndex} totalImages={images.length} onHandleChange={handleProgressChange} />
                    </div>
                </div>
            </div>
        </div>
    );
};