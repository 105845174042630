import React, {useState} from 'react';
import './settingItem.css';
import Button from '../../global/buttons';
import Toggle from '../../global/toggle';
import { sendInstruction } from '../../functions/controls/controlsEndpointFunctions'
import useAuth from '../../../hooks/useAuth' 
import { Modal } from '../../global/modals'
import { useTranslation } from 'react-i18next';


export const SettingItem = ({ color, header, headerText, buttonType, buttonText, isLogout = '', isWarning = false, instruction = '' }) => {
    const { t } = useTranslation('global');
    const { accessToken, logout } = useAuth();
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

    const toggleWarningModal = () => {
        setIsWarningModalOpen(prevState => !prevState);
    };

    const handleConfirm = () => {
        sendInstruction(instruction, '', accessToken);
        if (isLogout) {
            logout();
        } else {
            toggleWarningModal();
        } 
    };

    const handleButtonClick = () => {
        if (isWarning) {
            setIsWarningModalOpen(true);
        } else if (isLogout) {
            logout();
        } else {
            sendInstruction(instruction, '', accessToken);
        }
    };

    const handleToggleChange = (isToggleOn) => {
        console.log(`Toggle changed to ${isToggleOn ? 'ON' : 'OFF'}`);
    };

  return (
    <div className={`settings-wrapper-settings settings-${color}`}>
      <div className='settings-text'>
        <div className={`settings-text-header settings-text-header-${color}`}>
          {header}
        </div>
        <div className='settings-text-text'>
          {headerText}
        </div>
      </div>
      <div className='setting-button'>
        {buttonType === 'button' ? (
          <Button 
            type='primary'
            color={color} 
            label={buttonText} 
            onClick={handleButtonClick} 
          />
        ) : buttonType === 'toggle' ? (
          <Toggle 
            label={true} 
            onChange={handleToggleChange} 
          />
        ) : null}
      </div>
    <Modal
        isOpen={isWarningModalOpen}
        closeModal={toggleWarningModal}
        heading={t('settings.modals.action')}
        subheading={t('settings.modals.action_text')}
        buttons={true}
        btnLabel={t('settings.modals.button_confirm')}
        btnAction={handleConfirm} 
    />
    </div>
    
  );
};
