import './histProgram.css';
import React from 'react';
import { workspaceConfiguration } from '../../../blockly/blocklyConfig';
import {jsonToBlocklySvg} from '../../functions/blockly/utils'
const ProgramCode = React.memo(({ programId, program, currentlySelectedProgramId, handleSelectProgram }) => {  const { svgData, programWorkspace } = jsonToBlocklySvg(program.program, workspaceConfiguration, false);
  const programJSON = program.program;
  const programName = program.title;
  const programDate = program.updated;

  return (
    <HistProgram 
      programId={programId}
      programSvgComponent={svgData} 
      programJSON={programJSON} 
      programName={programName}
      programDate={programDate} 
      isActive={currentlySelectedProgramId === programId}
      onSelectProgram={() => handleSelectProgram(programId, programJSON)}
    />
  );
});


function HistProgram({ programId, programSvgComponent, isActive, programJSON, programName, programDate, onSelectProgram}) {
  
  const handleProgramClick = () => {
    onSelectProgram(programId, programJSON);
  };

  const activeClass = isActive ? 'active' : '';

  function reformatDate(dateString) {
    const [date, time] = dateString.split('T');
    const [day, month, year] = date.split('-');
    return new Date(`${year}-${month}-${day}T${time}`);
  }

  const programUpdated = reformatDate(programDate);
  const options = { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit'
  };

const newProgramDate = new Intl.DateTimeFormat('en-GB', options).format(programUpdated);
    return (
      <div className={`program-code-wrapper flex-col align-start justify-center ${activeClass}`} onClick={handleProgramClick}>
        <div className="histprogram-svg-icon">
          {programSvgComponent}
        </div>
        <div className="flex flex-col gap-4 flex-col align-start justify-center">
          <p className="histprogram-name small bold margin-bottom-0">{programName}</p>
          <p className="histprogram-date xsmall margin-bottom-0">{newProgramDate}</p>
        </div>
      </div>
      
    );
}

export {ProgramCode, HistProgram};
