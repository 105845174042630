import React from 'react'
import './settings.css'
import {SettingItem} from './pages/settings/settingItem';
import { useTranslation } from 'react-i18next';
import useAuth from '../hooks/useAuth';

const Settings = () => {
  const { t } = useTranslation('global');
  const { deviceName } = useAuth();

  return (
    <div className='settings-container'>
    <div className='settings-wrapper'>
    <div className='settings-content'>
      <div className='settings-section'>
      <div className="settings-section-heading">
      {t('settings.header_tinyfarm')}
      </div>
      <div className='settings-wrapper-wrapper'>
      <SettingItem color='green' header={t('settings.name')} headerText = {deviceName} />
      {/* <SettingItem color ='green' 
        header={t('settings.pin')} 
        headerText = '' 
        buttonType="button" 
        buttonText={t('settings.button_reset_pin')}
      /> */}
      <SettingItem color ='green' 
        header={t('settings.update')} 
        headerText={t('settings.update_text')} 
        buttonType="button" 
        buttonText={t('settings.button_update')} 
        instruction='update'
      />
      </div>
      </div>
      {/* <div className='settings-section'>
      <div className="settings-section-heading">
      {t('settings.header_camera')}
      </div>
      <div className='settings-wrapper-wrapper'>
      <SettingItem color ='green' header={t('settings.flash')} headerText = {t('settings.flash_text')} buttonType="toggle" buttonText='' />
      </div>
      </div> */}
      {/* <div className='settings-section'>
      <div className="settings-section-heading">
      {t('settings.header_data')}
      </div>
      <div className='settings-wrapper-wrapper'>
      <SettingItem color ='green' header={t('settings.export')} headerText = {t('settings.export_text')} buttonType="button" buttonText={t('settings.button_export')} />
      </div>
      </div> */}
      <div className='settings-section'>
      <div className="settings-section-heading">
      {t('settings.header_connectivity')}
      </div>
      <div className='settings-wrapper-wrapper'>
      <SettingItem color ='green' 
        header={t('settings.wifi')} 
        headerText = {t('settings.wifi_text')} 
        buttonType="button" 
        buttonText={t('settings.button_wifi')} 
        isLogout={true}
        isWarning={true}
        instruction='wifi_reset'  
      />
      </div>
      </div>
      <div className='settings-section'>
      <div className="settings-section-heading settings-section-red">
      {t('settings.header_danger')}
      </div>
      <div className='settings-wrapper-wrapper settings-wrapper-wrapper-red'>
      {/* <SettingItem color ='red' header={t('settings.clear_data')} headerText = {t('settings.clear_data_text')} buttonType="button" buttonText={t('settings.button_clear_data')} /> */}
      <SettingItem color ='red' 
        header={t('settings.reset_tinyfarm')} 
        headerText = {t('settings.reset_tinyfarm_text')} 
        buttonType="button" 
        isWarning={true}
        buttonText={t('settings.button_reset_tinyfarm')} 
        isLogout={true}
        instruction='device_reset'
      />
       <SettingItem color ='red' 
        header={t('settings.factory_reset_tinyfarm')} 
        headerText = {t('settings.factory_reset_tinyfarm_text')} 
        buttonType="button" 
        isWarning={true}
        buttonText={t('settings.button_factory_reset_tinyfarm')} 
        isLogout={true}
        instruction='factory_reset'
      />
      <SettingItem color ='red' 
        header={t('settings.module_reset_tinyfarm')} 
        headerText = {t('settings.module_reset_tinyfarm_text')} 
        buttonType="button" 
        isWarning={true}
        buttonText={t('settings.button_module_reset_tinyfarm')} 
        isLogout={false}
        instruction='module_reset'
      />
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Settings;