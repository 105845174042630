import config from "../../../config";

const getDeviceInfo = async (accessToken) => {
  const storedName = localStorage.getItem('deviceName');
  const storedId = localStorage.getItem('deviceId');

  if (storedName && storedId) {
    return { name: storedName, id: storedId };
  }

  const url = `${config.apiEndpoint}/devices/get_info`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to get device info: ${response.statusText}`);
    }

    const data = await response.json();

    localStorage.setItem('deviceName', data.name);
    localStorage.setItem('deviceId', data.id);

    return { name: data.name, id: data.id };

  } catch (error) {
    console.error('Error:', error);
    return { name: null, id: null };  
  }
};

export default getDeviceInfo;
