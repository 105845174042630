import './controls.css';
import React, { useState, useEffect, useRef } from 'react';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { setupSocket } from './functions/controls/controlsEndpointFunctions';
import { sendInstruction } from './functions/controls/controlsEndpointFunctions';
// Components
import ModularComponent from './pages/controls/modularSensor.js';
import ControlHeader from './pages/controls/controlHeader.js';

function Controls() {
    const { t } = useTranslation(['controls', 'sensors']);
    const { accessToken, deviceId } = useAuth();
    const [sensors, setSensors] = useState([]);
    const [sensorData, setSensorData] = useState({});
    const socketRef = useRef(null);
    
   useEffect(() => {
    if (!socketRef.current && accessToken && deviceId) {
        socketRef.current = setupSocket(accessToken, deviceId);
        socketRef.current.on(`modular_info_${deviceId}`, updateSensors);
        socketRef.current.on(`modular_data_${deviceId}`, updateSensorData);
        sendInstruction(accessToken, 'publish_modular_info');
    }
    
    return () => {
        if (socketRef.current) {
            socketRef.current.disconnect();
            socketRef.current = null;
        }
    };
  }, [accessToken, deviceId]); 

  const updateSensors = (data) => {
      const sortedSensors = data.info.sort((a, b) => parseInt(a.number, 10) - parseInt(b.number, 10));
      setSensors(sortedSensors);
  };

  const updateSensorData = (data) => {
    const sensorKey = `${data.sensor_type}_${data.sensor_number}`;
    setSensorData(prevData => {
        const newData = {
            ...prevData[sensorKey],
            sensor_type: data.sensor_type,
            sensor_number: data.sensor_number,
            value: data.value,
            lastValue: data.lastValue,
            battery: data.battery,
            sleeping: data.sleeping,
            interval: data.interval,
            protocol: data.protocol,
            charging: data.charging
        };
        return { ...prevData, [sensorKey]: newData };
    });
  };
  const sensorTypes = 
                    ['temperature', 
                    'humidity', 
                    'pressure', 
                    'light_lux',
                    'altitude',
                    'tvoc', 
                    'co2'];
  const actuatorTypes = ['camera', 
  'pump'];
  const sensorsForModularSensor = sensors.filter(sensor => sensorTypes.includes(sensor.type));
  const actuatorsForModularActuator = sensors.filter(sensor => actuatorTypes.includes(sensor.type));
  
  useEffect(() => {
    console.log(sensors);
    console.log(sensorData);  
  }, [sensors, sensorData]);

  return (
        <div className="controls flex h-full items-center justify-center">
            <div className="h-full flex flex-col justify-start conditions-wrapper">
                <div className="flex flex-col items-center justify-center sensors-wrapper">
                {sensorsForModularSensor.length > 0 && <ControlHeader text={t(`sensors:sensors_header`)} />}
                    <div className="sensors-container grid gap-24 w-full">
                        {sensorsForModularSensor.map(sensor => (
                            <ModularComponent
                                key={`${sensor.type}_${sensor.number}`}
                                sensorId={`${sensor.type}_${sensor.number}`}
                                sensor_type={sensor.type}
                                sensor_number={sensor.number}
                                sensor_protocol={sensor.protocol}
                                sensor_interval={sensor.interval}
                                is_sleeping={sensor.sleeping}
                                last_value={sensor.lastValue}
                                name={t(`sensors:${sensor.type}`)}
                                accessToken={accessToken}
                                sensorData={sensorData[`${sensor.type}_${sensor.number}`]}
                                component_type='sensor'
                                initialBattery={sensor.battery}
                            />
                        ))}
                    </div>
                    {actuatorsForModularActuator.length > 0 && <ControlHeader text={t(`sensors:actuators_header`)} />}
                    <div className='actuators-container grid gap-24 w-full'>
                        {actuatorsForModularActuator.map(actuator => (
                            <ModularComponent
                                key={`${actuator.type}_${actuator.number}`}
                                sensorId={`${actuator.type}_${actuator.number}`}
                                sensor_type={actuator.type}
                                sensor_number={actuator.number}
                                sensor_protocol={actuator.protocol}
                                sensor_interval={actuator.interval}
                                is_sleeping={actuator.sleeping}
                                last_value={actuator.lastValue}
                                name={t(`sensors:${actuator.type}`)}
                                accessToken={accessToken}
                                component_type='actuator'
                                sensorData={sensorData[`${actuator.type}_${actuator.number}`]}
                                initialBattery={actuator.battery} 
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Controls;
