import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { persistor } from '../redux/store'
import config from '../config';
import jwtDecode from "jwt-decode";

const useAuth = () => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'));
  const [deviceName, setDeviceName] = useState(localStorage.getItem('deviceName'));
  const [deviceId, setDeviceId] = useState(localStorage.getItem('deviceId'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);

  const isAuthenticated = !!accessToken;


  const logout = useCallback(() => {
    localStorage.clear();
    persistor.purge();
    setAccessToken(null);
    setDeviceName(null);
    setDeviceId(null);
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    if (accessToken && !tokenExpired) {
      const decodedToken = jwtDecode(accessToken);
      const currentTime = new Date().getTime() / 1000;
      if (decodedToken.exp < currentTime) {
        setTokenExpired(true);
      }
    }
  }, [accessToken, tokenExpired]);

  useEffect(() => {
    if (tokenExpired) {
      logout();
    }
  }, [tokenExpired, logout]);
  
  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token');

    // If no refresh token and not authenticated, navigate to login
    if (!refreshToken && !isAuthenticated) {
      navigate('/login');
      setLoading(false);
      return;
    }

    const refreshAccessToken = async () => {
      setLoading(true);
      const response = await fetch(`${config.apiEndpoint}/devices/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });

      if (response.status !== 200) {
        logout();
        setLoading(false);
        return;
      }

      const data = await response.json();
      localStorage.setItem('access_token', data.access_token);
      setAccessToken(data.access_token);

    
      // Fetch device info after the access token is refreshed
      const responseDeviceInfo = await fetch(`${config.apiEndpoint}/devices/get_info`, {
        headers: {
          'Authorization': `Bearer ${data.access_token}`,
        },
      });

      if (responseDeviceInfo.status === 200) {
        const deviceInfo = await responseDeviceInfo.json();
        setDeviceName(deviceInfo.name);
        setDeviceId(deviceInfo.id);
        localStorage.setItem('deviceName', deviceInfo.name);
        localStorage.setItem('deviceId', deviceInfo.id);
      }
      setLoading(false);
    };

    // Only attempt to refresh the token if not already authenticated
    if (!isAuthenticated) {
      refreshAccessToken();
    } else {
      setLoading(false);
    }
  }, [navigate, logout, isAuthenticated]);
  
  useEffect(() => {
    if (window.location.pathname === '/logout') {
      logout();
    }
  }, [logout]);

  return { accessToken, isAuthenticated, logout, deviceName, deviceId, loading };
};

export default useAuth;
