import './courseBlock.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BoxedIcon } from '../../global/boxedicon';
import {ReactComponent as ChevronRight} from '../../../assets/global/chevron-right.svg';

const CourseBlock = ({ icon, courseName, courseTitle, courseDescription, courseLink, color }) => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        console.log("navigating to course");
        navigate(courseLink);
    };

    return (
        <div className={`course-block-wrapper course-block-${color}`} onClick={handleRedirect}>
            <BoxedIcon state='idle' icon={icon} className="boxed-icon-large"/>
            <div className="course-block-content flex-col align-start justify-center">
                <div className="course-block-preheader">{courseTitle}</div>
                <div className="course-block-heading">{courseName}</div>
                <div className="course-block-description">{courseDescription}</div>
            </div>
            <ChevronRight className="course-block-chevron" color="green"/>
        </div>
    );
};

export default CourseBlock;
