import './sidebar.css';
import React, { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import getDeviceInfo from './functions/auth/deviceInfo';
import { getBitmap } from './functions/sidebar/sidebarEndpointFunctions';
import Button from './global/buttons'
import LanguagePicker from './pages/sidebar/languagePicker';
import { Link, useLocation } from 'react-router-dom';
import {ReactComponent as Logo} from '../assets/global/logo-small.svg';
import Clock from 'react-live-clock'

const Sidebar = () => {
  const { t } = useTranslation('global');
  const { accessToken } = useAuth();
  const buttonType = (path) => {
    const currentPath = window.location.pathname.slice(1);
    if (currentPath === path) {
      return 'primary';
    } else if (currentPath.includes(path) && path !== "") {
      return 'primary';
    } else {
      return 'secondary';
    }
  };

  const [bitmapData, setBitmapData] = useState(null);
  const [deviceName, setDeviceName] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [retries, setRetries] = useState(0);
  
  const RETRY_DELAY = 2000;  
  const MAX_RETRIES = 3;

  useEffect(() => {
    if (!bitmapData || !deviceName || !deviceId) {
      if (retries < MAX_RETRIES) {
        if (!bitmapData) fetchBitmap();
        if (!deviceName || !deviceId) fetchDeviceInfo();
      } else {
        console.error("Max retry attempts reached.");
      }
    }
  }, [bitmapData, deviceName, deviceId, retries]);

  const fetchBitmap = async () => {
    try {
      if (accessToken && accessToken.split('.').length === 3) {
        const response = await getBitmap(accessToken, deviceName);
        setBitmapData(response);
      }
    } catch (error) {
      console.error("Failed fetching bitmap:", error);
      retry();
    }
  };

  const fetchDeviceInfo = async () => {
    try {
      const { name, id } = await getDeviceInfo(accessToken);
      setDeviceName(name);
      setDeviceId(id);
    } catch (error) {
      console.error("Failed fetching device info:", error);
      retry();
    }
  };
  

  const retry = () => {
    setTimeout(() => {
      setRetries(prev => prev + 1);
    }, RETRY_DELAY);
  };


  return (
    <div className="w-1/6 sidebar flex-none flex flex-col justify-between items-center">
      <div className="items-center justify-center buttons-section">
        <div className="flex w-full sidebar-header justify-center align-center">
          <div className="flex justify-center items-center">
          <Link to="/" className="sidebar-header-avatar">
            {bitmapData ? (
              <img src={bitmapData} className="bitmap-image" alt="Logo" />
            ) : (
              <Logo />
            )}
          </Link>
          </div>
          <div className="flex flex-col justify-center items-center sidebar-header-text">
          <div className="tinyfarm-name">{deviceName || t('loading')}</div>
          <div className="tinyfarm-id">{t('sidebar_tinyfarmID')}: {deviceId || t('loading')}</div>
          <Clock format={'HH:mm:ss'} ticking={true} />
          </div>
        </div>
        <Link to="/" className="sidebar-link">
          <Button type={buttonType("")} className="sidebar-button" color="purple" label={t('sidebar_controlPanel')} iconFirst="🕹️" />
        </Link>
        {/* <Link to="/programming" className="sidebar-link">
          <Button type={buttonType("programming")} className="sidebar-button" color="purple" label={t('sidebar_programming')} iconFirst="🤖" />
        </Link> */}
        {/* <Link to="/analytics" className="sidebar-link">
          <Button type={buttonType("analytics")} className="sidebar-button" color="purple" label={t('sidebar_analytics')} iconFirst="📈" />
        </Link> */}
        <Link to="/gallery" className="sidebar-link">
          <Button type={buttonType("gallery")} className="sidebar-button" color="purple" label={t('sidebar_gallery')} iconFirst="🖼️"/>
        </Link>
        {/* <Link to="/learning" className="sidebar-link">
          <Button type={buttonType("learning")} className="sidebar-button" color="purple" label={t('sidebar_learning')} iconFirst="📚" />
        </Link> */}
      </div>
      <div className="items-center justify-center buttons-section admin-wrapper">
        <LanguagePicker />
        <Link to="/settings" className="sidebar-link">
          <Button type={buttonType("settings")} className="sidebar-button" color="purple" label={t('sidebar_settings')} iconFirst="⚙️" />
        </Link>
        <Link to="/logout" className="sidebar-link">
          <Button type={buttonType("logout")} className="sidebar-button" color="purple" label={t('sidebar_logout')} iconFirst="👋" />
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
