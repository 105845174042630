  import React, { useEffect, useState } from 'react';
  import { BoxedIcon } from '../../global/boxedicon';
  import './modularSensor.css';
  import BatteryIndicator from './sensors/battery';
  import DeleteSensor from './sensors/delete'
  import RefreshIcon from './sensors/refresh';
  import { useTranslation } from 'react-i18next';
  import { sendInstruction } from '../../functions/controls/controlsEndpointFunctions';
  import { IntervalSlider } from './slider'
  import { NumberInput } from '../../global/selectors';
  import Button from '../../global/buttons';
  import { ReactComponent as Lora } from '../../../assets/controls/lora.svg';
  import { ReactComponent as Thread } from '../../../assets/controls/thread.svg';
  import { ReactComponent as EspNow } from '../../../assets/controls/espnow.svg';
  import { ReactComponent as Awake } from '../../../assets/controls/sun.svg';
  import { ReactComponent as Sleeping } from '../../../assets/controls/sleep.svg';
  

  const SensorSVGOptions = ({ isVisible, onClose, handleProtocolChange }) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        const element = event.target;
        
        if (!element.closest('.sensor-svg-options')) {
          onClose(); 
        }
      };
  
      if (isVisible) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isVisible, onClose]);
  
    const handleProtocolSelection = (protocol) => {
      handleProtocolChange(protocol);
      onClose(); 
    };
  
    if (!isVisible) return null;
  
    return (
      <div className="sensor-svg-options-backdrop" onClick={onClose}>
        <div className="sensor-svg-options" onClick={e => e.stopPropagation()}>
          <div onClick={() => handleProtocolSelection('lora')}>
            <Lora />
          </div>
          <div onClick={() => handleProtocolSelection('thread')}>
            <Thread />
          </div>
        </div>
      </div>
    );
  };

const getSensorDetails = (sensorType, t) => {
    let details = {
        icon: '',
        unit: '',
        name: t(`${sensorType}`)
    };

    switch (sensorType) {
        case 'temperature':
            details.icon = '🌡️';
            details.unit = '°C'; 
            break;
        case 'humidity':
            details.icon = '💧';
            details.unit = '%';
            break;
        case 'pressure':
            details.icon = '🌬️';
            details.unit = 'hPa';
            break;
        case 'light_lux':
            details.icon = '💡';
            details.unit = 'lux';
            break;
        case 'tvoc':
            details.icon = '🏭';
            details.unit = 'ppb';
            break;
        case 'altitude':
              details.icon = '⛰️';
              details.unit = 'm';
            break;
        case 'co2':
            details.icon = '🌿';
            details.unit = 'ppm';
            break;
        default:
            details.icon = '❓';
            details.unit = '';
            break;
    }

    return details;
  };
  
  const getActuatorDetails = (actuatorType, actuatorNumber, t) => {
    let details = {
        icon: '',
        unit: '',
        name: t(`${actuatorType}`)
    };

    switch (actuatorType) {
        case 'camera':
            details.icon = '📷';
            details.instruction = 'take_picture_modular';
            details.button = t('camera_button');
            details.instructionvalue= {
              'camera_number': actuatorNumber
            };
            break;
        case 'pump':
            details.icon = '🌀';
            details.instruction ='';
            details.instructionvalue = '';
            break;
        default:
            details.icon = '❓';
            details.instruction ='';
            details.instructionvalue = '';
            break;
    }

    return details;
  };

  function getSensorSvg(sensorProtocol) {
    switch (sensorProtocol) {
      case 'esp-now':
        return EspNow;
      case 'lora':
        return Lora;
      case 'thread':
        return Thread;
      default:
        return null; 
    }
  }
  
const ModularComponent = ({
  sensorData, sensorId, sensor_type, sensor_interval, is_sleeping,
  sensor_protocol, sensor_number, last_value, accessToken, component_type, initialBattery
}) => {
  const { t } = useTranslation('sensors');
  const [value, setValue] = useState(last_value);
  const [batteryLevel, setBatteryLevel] = useState(0);
  const [isCharging, setIsCharging] = useState(false);
  const [blockState, setBlockState] = useState(last_value !== null && last_value !== undefined ? 'idle' : 'inactive');
  const [displayValue, setDisplayValue] = useState('No data');
  const [fadeOut, setFadeOut] = useState(false);
  const [showSensorOptions, setShowSensorOptions] = useState(false);
  const [currentProtocol, setCurrentProtocol] = useState(sensor_protocol);

  const timeValues = [
    { unit: 's', label: 1, value: 1 },
    { unit: 's', label: 10, value: 10 },
    { unit: 'm', label: 1, value: 60 },
    { unit: 'm', label: 10, value: 600 },
    { unit: 'h', label: 1, value: 3600 },
    { unit: 'd', label: 1, value: 86400 },
  ];

  const storedValue = localStorage.getItem(sensorId);
  let initialValue = 1;
  const sensorIntervalNumber = parseInt(sensor_interval, 10);
  if (sensorIntervalNumber && timeValues.some(t => t.value === sensorIntervalNumber)) {
    initialValue = sensorIntervalNumber;
  } else if (storedValue) {
    initialValue = parseInt(storedValue, 10);
  }
  const initialTimeObject = timeValues.find(t => t.value === initialValue) || timeValues[0];
  const [selectedTime, setSelectedTime] = useState(initialTimeObject);
  const [sliderValue, setSliderValue] = useState(initialTimeObject.value);

  // Determine if this is a sensor or actuator based on component_type
  const isActuator = component_type === 'actuator';
  const details = isActuator ? getActuatorDetails(sensor_type, sensor_number, t) : getSensorDetails(sensor_type, t);
  const icon = details.icon;
  const unit = details.unit;
  const name = details.name;
  const instruction = details.instruction;
  const instructionValue = details.instructionvalue;
  const instructionButton = details.button;

  useEffect(() => {
    localStorage.setItem(sensorId, JSON.stringify(selectedTime.value));
  }, [selectedTime, sensorId]);

  useEffect(() => {
    if (sensorData) {
      setValue(sensorData.value);
      setBatteryLevel(sensorData.battery);
      setIsCharging(sensorData.charging);
      setBlockState('active');
      setTimeout(() => setBlockState('idle'), 1000);
    }
  }, [sensorData, sensor_type]);

  useEffect(() => {
    setDisplayValue(value !== null && value !== undefined && value !== '' ? value : 'No data');
  }, [value]);

  const handleChange = (newValue) => {  
    setSliderValue(newValue);
    const newSelectedTime = timeValues.find(t => t.value === newValue);
    setSelectedTime(newSelectedTime);
  };
  const handleDelete = () => {
    setFadeOut(true);
    setTimeout(() => {
      sendInstruction(accessToken, 'module_delete', { 'sensor_number': sensor_number, 'sensor_type': sensor_type });
    }, 500);
  };

  const handleUpdateInterval = (newValue) => {
    sendInstruction(accessToken, 'set_interval', {
      'sensor_type': sensor_type,
      'sensor_number': sensor_number,
      'value': newValue
    });
  };

  const handleProtocolChange = (newProtocol) => {
    setCurrentProtocol(newProtocol);
    sendInstruction(accessToken, 'set_protocol', { 
      'sensor_number': sensor_number, 
      'sensor_type': sensor_type, 
      'protocol': newProtocol 
    });
  };

  const handleActuatorAction = () => {
    if (isActuator) {
      sendInstruction(accessToken, instruction, instructionValue);
    }
  };
  useEffect(() => {
    setBatteryLevel(initialBattery);
  }, [initialBattery]); 

  const SensorSVG = getSensorSvg(currentProtocol);

  return (
    <div className={`sensor-block sensor-block-${blockState} ${fadeOut ? 'fade-out' : ''} ${isActuator ? 'actuator-block' : ''}`}>
      <div className='sensor-block-header'>
        <div className='sensor-block-svg sensor-battery-wrapper'>
          <BatteryIndicator percentage={batteryLevel} charging={isCharging} />
        </div>
        <div className='sensor-block-svg'>
          {is_sleeping === "false" ? <Awake /> : <Sleeping />}
        </div>
        <div className='sensor-block-svg sensor-delete-svg'>
          <DeleteSensor onClick={handleDelete} />
        </div>
        <div className='sensor-block-svg sensor-sleep-svg'>
          <RefreshIcon />
        </div>
        <div className='sensor-block-svg sensor-protocol-svg' onClick={() => setShowSensorOptions(prev => !prev)}>
          <SensorSVG />
          {!isActuator && <SensorSVGOptions isVisible={showSensorOptions} handleProtocolChange={handleProtocolChange} onClose={() => setShowSensorOptions(false)} />}
        </div>
      </div>
      <div className='sensor-middle'>
        <BoxedIcon state={blockState} icon={icon} />
        <div className="flex flex-col ml-4 sensor-data">
          <div className="flex space-between items-center sensor-frame">
            <span className="sensor-type-span">{name} {sensor_number}</span>
          </div>
          <div className="flex justify-start items-center sensor-data-values">
          <div className="flex justify-start items-center sensor-data-values">
          {!isActuator ? (
            <>
            <span className={`sensor-value-span text-value ${blockState === 'active' ? 'sensor-block-active' : ''}`}>{displayValue}</span>
            {blockState !== 'inactive' && <span className={`text-unit ${blockState === 'active' ? 'sensor-block-active' : ''}`}>{unit}</span>}
            </>
          ) : (
            <Button type='primary' color='purple' label={instructionButton} onClick={handleActuatorAction} />
          )}
        </div>
          </div>
        </div>
      </div>
      <div className='sensor-bottom'>
        <div className='measure-interval-text'>{t('interval')}</div>
        <div className='measure-controls'>
          <div className='measure-interval-slider'>
            <IntervalSlider onChange={handleChange} onMouseUp={handleUpdateInterval} timeValues={timeValues} initialValue={sliderValue} />
          </div>
          <div className='measure-interval-input'>
            <NumberInput unit={selectedTime.unit} value={selectedTime.label} readOnly={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ModularComponent);