import config from '../../../config';


export const saveBlocklyProgram = async (name, blocklyJson, accessToken, active, is_autosaved) => {
  const url = `${config.apiEndpoint}/devices/blockly`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ // Sending data in request body
        program: blocklyJson,
        title: name,
        active: active,
        is_autosaved: is_autosaved
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to save code: ${response.statusText}`);
    }

    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error('Error:', error);
  }
};


export const sendJsonCommandDevice = async (blocklyJson, xmlJson, autosavetext, accessToken, active) => {
  const url = `${config.apiEndpoint}/devices/instruction`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ 
        value: blocklyJson,
        instruction: 'blockly',
      }),
    });
    if (!response.ok) {
      throw new Error(`Failed to run code: ${response}`);
    }
    
    saveBlocklyProgram(autosavetext,xmlJson,accessToken,active,true);
  } catch (error) {
    console.error('Error:', error);
  }
};

export const sendStopCommandDevice = async (accessToken) => {
  sendJsonCommandDevice({},{},'',accessToken,false)
};

export async function getProgramsJSON(accessToken, amount, running){
  const url = `${config.apiEndpoint}/devices/blockly?programs=${amount}`;
  
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to obtain programs: ${response.statusText}`);
    }

    const data = await response.json();

    if (running) {
      if (data && data.length > 0 && data[0].program !== undefined) {
        const program = data[0].program;
        return program;
      } else {
        console.log("No running programs found.");
        return null;
      }
    } else {
      return data;
    }

  } catch (error) {
    console.error('Error:', error);
  }
}
