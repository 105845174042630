
import config from '../../../config';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { createFFmpeg, FFmpeg } from '@ffmpeg/ffmpeg';

export const getHistoricalImagesDevice = (accessToken, amount) => {
    const url = `${config.apiEndpoint}/devices/get_picture?amount=${amount}`;
  
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to get pictures: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => data) 
      .catch(error => {
        console.error('Error:', error);
        return []; 
      });
  };

export const deletePicturesDevice = (accessToken, images) => {
    const url = `${config.apiEndpoint}/devices/delete_pictures`;
    console.log(images);
    const formattedDatetimes = images.map(images => {
      const date = new Date(images.date);
      return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    });
    const datetimeJsonString = JSON.stringify(formattedDatetimes);
  
    return fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ datetimes: datetimeJsonString }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      // console.log('Success:', data);
      return data;
    })
    .catch(error => {
      // console.error('Error:', error);
      return [];
    });
  };
  

  export const downloadImage = (image) => {
    // Assuming image is an object with 'img' and 'date' properties
    const zip = new JSZip();
    const folder = zip.folder("images");

    // Extract the base64 data from the image object
    const imgData = image.img.replace(/^data:image\/(png|jpeg);base64,/, "");
    const imgBlob = b64toBlob(imgData, "image/jpeg");

    // Create a safe file name from the image date
    const safeFileName = image.date.replace(/:/g, '-') + ".jpg";
    folder.file(safeFileName, imgBlob, { base64: true });

    // Generate the zip file and trigger the download
    zip.generateAsync({ type: "blob" })
        .then((content) => {
            saveAs(content, "tinyfarms-image.zip");
        });
};
  
export const downloadSelectedImages = (selectedImageObjects) => {
  const zip = new JSZip();
  const folder = zip.folder("images");
  
  selectedImageObjects.forEach((image) => {
    const imgData = image.img.replace(/^data:image\/(png|jpeg);base64,/, "");
    const imgBlob = b64toBlob(imgData, "image/jpeg");
    const safeFileName = image.date.replace(/:/g, '-') + ".jpg";
    folder.file(safeFileName, imgBlob, { base64: true });
  });

  zip.generateAsync({ type: "blob" })
    .then((content) => {
      saveAs(content, "tinyfarms-images.zip");
    });
};
export async function downloadTimelapse(images) {
  if (images.length === 0) {
      console.error('No images provided for timelapse.');
      return;
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = 1280;
  canvas.height = 1024;

  const loadImage = (base64String) => {
      return new Promise(resolve => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.src = `data:image/jpeg;base64,${base64String}`;
      });
  };

  const firstImage = await loadImage(images[0].img);
  context.drawImage(firstImage, 0, 0, canvas.width, canvas.height);

  const stream = canvas.captureStream();
  const mimeType = 'video/webm';
  if (!MediaRecorder.isTypeSupported(mimeType)) {
      alert('MediaRecorder does not support the required video format.');
      return;
  }

  const mediaRecorder = new MediaRecorder(stream, { mimeType: mimeType });
  let chunks = [];
  mediaRecorder.ondataavailable = e => chunks.push(e.data);
  mediaRecorder.onstop = async () => {
      const blob = new Blob(chunks, { 'type': mimeType });
      const videoURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = videoURL;
      a.download = 'timelapse.webm';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(videoURL);
  };

  mediaRecorder.start();

  for (let i = 1; i < images.length; i++) {
      const img = await loadImage(images[i].img);
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      await new Promise(resolve => setTimeout(resolve, 1000)); // Frame duration
  }

  mediaRecorder.stop();
}


  
  // Convert a base64 string to a Blob
  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };