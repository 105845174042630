import React from 'react';

const RefreshIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6V2L8 6l4 4V8c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3H6c0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5z" fill="#7870E6"/>
        </svg>
    );
};

export default RefreshIcon;