import './toasts.css';
import Button from './buttons';
import { useTranslation } from 'react-i18next';

// const InfoToast = ({ label, color, emoji, btnLabel, btnAction, btn2Label, btn2Action, btn3Label, btn3Action, preview, classes, children }) => {
//   const { t } = useTranslation('global');
//   return (
//     <>
//     <div className={`flex flex-row toast toast-${color} ${classes}`}>
//       <div className='toast-preview'>{preview}</div>
//       <div className="flex flex-row items-center justify-center toast-left">
//         <div className='toast-message-wrapper'>
//           {emoji && <span className='emoji-md'>{emoji}</span>}
//           <div className="toast-label small bold">{label}</div>
//         </div>
//       </div>
//       {btn2Label || btn3Label ? (
//         <div className="button-group">
//           {btn3Label && <Button label={btn3Label} type="primary" color={color} small onClick={btn3Action}></Button>}
//           {btn2Label && <Button label={btn2Label} type="primary" color={color} small onClick={btn2Action}></Button>}
//           <Button label={btnLabel} type="primary" color={color} small onClick={btnAction}></Button>
//         </div>
//       ) : btnLabel ? (
//         <Button label={btnLabel} type="primary" color={color} small onClick={btnAction}></Button>
//       ) : (
//         <Button label={t('close')} type="primary" color={color} small onClick={btnAction}></Button>
//       )}
//     </div>
//     {children}
//     </>
//   );
// };

const InfoToast = ({ color, emoji, label, buttons, preview, className, children }) => {
  const { t } = useTranslation('global');

  return (
    <>
      <div className={`flex flex-row toast toast-${color} ${className}`}>
        {preview && <div className='toast-preview'>{preview}</div>}
        <div className="flex flex-row items-center justify-center toast-left">
          <div className='toast-message-wrapper'>
            {emoji && <span className='emoji-md'>{emoji}</span>}
            <div className="toast-label small bold">{label}</div>
          </div>
        </div>
        {buttons && <div className="button-group">
          {buttons.map((button, index) => (
            <Button 
              key={index}
              label={button.label || t('close')} 
              type="primary" 
              color={button.color || color}
              small 
              onClick={button.action}
            />
          ))}
          </div>
        }
      </div>
      {children}
    </>
  );
};


export default InfoToast;
