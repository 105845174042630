import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pumpValues: {}, 
  };
  const pumpsSlice = createSlice({
    name: 'pumps',
    initialState,
    reducers: {
      setPumpValue(state, action) {
        const { pumpId, value } = action.payload;
        state.pumpValues[pumpId] = value;
      },
    },
});
  
export const { setPumpValue } = pumpsSlice.actions;

export default pumpsSlice.reducer;