import './correct.css';
import '../running.css';
import React, {useState} from 'react';
import {Modal} from '../../../global/modals'
import Button from '../../../global/buttons'
import { jsonToBlocklyWorkspace, jsonToBlocklySvg } from '../../../functions/blockly/utils';
import { useTranslation } from 'react-i18next';

function ProgramCorrect({ attemptedProgramJSON, isProgramRunning, programCorrectJSON, currentWorkspace, workspaceConfig}) {
  const { t } = useTranslation('programming');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const animationClass = isProgramRunning ? '-is-running' : '-is-stopped';
  const attemptedProgramString = JSON.stringify(attemptedProgramJSON);
  const correctProgramString = JSON.stringify(programCorrectJSON);

  const { svgData: programCorrectSvgComponent, programWorkspace: newProgramWorkspace } = jsonToBlocklySvg(programCorrectJSON, workspaceConfig, false);
  console.log(programCorrectSvgComponent);

  const handleViewClick = () => {
    setIsModalOpen(true); 
  };

  const duplicateToWorkspace = () => {
    jsonToBlocklyWorkspace(programCorrectJSON, currentWorkspace)
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Determine the message based on the comparison of the JSON objects
  const programMessage = attemptedProgramString === correctProgramString ? "Se anbefalet løsning" : "Se anbefalet løsning";
  console.log("attempted program", attemptedProgramString);
  console.log("correct program", correctProgramString);
  return (
    <div className={`flex flex-row program-running-wrapper ${animationClass}`}>
        <div className="flex flex-row items-center justify-center program-running-left">
          <div className="program-running-running">{programMessage}</div>
        </div>
        <div className="flex flex-row items-center justify-center program-running-right">
          <button className="program-running-button" onClick={duplicateToWorkspace}>{t('duplicate')}</button>
          <button className="program-running-button" onClick={handleViewClick}>{t('view')}</button>
        </div>
        <Modal isOpen={isModalOpen} closeModal={handleCloseModal}>
          <h3 className="modal-heading">{t('modals.correct.correctCodeTitle')}</h3>
          <div className="modal-paragraph flex-col space-between">
            <div className="paragraph-bottom">{t('modals.correct.correctCodeParagraph')}</div>
            <div className="program-running-preview">{programCorrectSvgComponent}</div>
          </div>
        </Modal>
    </div>
  );
}

export default ProgramCorrect;

