import React from 'react';
import './battery.css'

const BatteryIndicator = ({ percentage, charging }) => {
    const batteryLevel = Math.max(0, Math.min(100, percentage)); 
    const fillColor = batteryLevel > 50 ? '#00B868' : batteryLevel > 20 ? '#FFC300' : '#E15656';

    const svgWidth = 30; 
    const svgHeight = 16; 
    const batteryWidth = 25; 
    const batteryHeight = 14; 
    const terminalWidth = 2.5; 
    const terminalHeight = 5; 
    
    const boltWidth = 10; 
    const boltHeight = 6;
    const boltStartX = 1 + (batteryWidth - boltWidth) / 2; 
    const boltStartY = 1 + (batteryHeight - boltHeight) / 2; 
    return (
        <div className="sensor-battery">
            <div className='sensor-battery-percentage' style={{ color: fillColor }}>
                {percentage} %
            </div>
            <div className='sensor-battery-svg'>
                <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`} xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width={batteryWidth} height={batteryHeight} stroke={fillColor} fill="none"/>
                    <rect x={batteryWidth + 1} y={(svgHeight - terminalHeight) / 2} width={terminalWidth} height={terminalHeight} fill={fillColor}/>
                    <rect x="2" y="2" width={batteryLevel * (batteryWidth / 100)} height={batteryHeight - 2} fill={fillColor}/>
                    {/* {charging && (
                        <path d={`M ${boltStartX},${boltStartY} L ${boltStartX + 3},${boltStartY + 3} L ${boltStartX + 1},${boltStartY + 3} L ${boltStartX + 4},${boltStartY + 6} L ${boltStartX + 2},${boltStartY + 4} L ${boltStartX + 5},${boltStartY + 4} Z`} fill={fillColor} />
                    )} */}
                </svg>
            </div>
        </div>
    );
};

export default BatteryIndicator;
