import './languagePicker.css'
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../global/buttons';
import Dropdown from '../../global/selectors';
import langs from 'langs'
import {ReactComponent as Chevron} from '../../../assets/global/chevron.svg';

const LanguagePicker = () => {
  const { i18n } = useTranslation('global');
  const languages = i18n.options.resources.availableLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.slice(0, 2).toUpperCase());
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.toLowerCase());
  }, [selectedLanguage]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const otherLanguages = languages.filter((lang) => lang.toUpperCase() !== selectedLanguage);

  const fullLanguageName = (code) => {
    const languageInfo = langs.where('1', code.toLowerCase());
    return languageInfo ? languageInfo.local : code.toUpperCase();
  }

  return (
    <div className="nav_text_wrapper language-selector" ref={wrapperRef} onClick={() => setIsOpen(!isOpen)}>
      <Button
        type='secondary'
        color='purple'
        iconFirst="🌐"
        label={selectedLanguage}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        iconLast={<Chevron />}
        className="sidebar-button"
        customStyles={{}} 
      />
      {isOpen && (
        <ul className={`dropdown-menu open-top ${isOpen ? 'open' : ''}`}>
          {[selectedLanguage, ...otherLanguages].map((language) => (
            <li className="dropdown-item" key={language} onClick={(e) => {
              e.stopPropagation();
              setSelectedLanguage(language.toUpperCase());
              setIsOpen(false);
            }}>
              <div className="dropdown-label">{fullLanguageName(language)}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default LanguagePicker;
