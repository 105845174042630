import './login.css';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import logo from '../assets/login/tinyfarms-logo.svg';
import InfoToast from './global/toasts';
import { ReactComponent as EPaperDisplay} from '../assets/login/e-paper-display.svg';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation('global');

  const [name1, setname1] = useState('');
  const [name2, setname2] = useState('');
  const [pin, setPin] = useState(['', '', '', '']);
  const pinInputRefs = [useRef(), useRef(), useRef(), useRef()];
  const navigate = useNavigate();

  // toast logic
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastColor] = useState('');
  
  const handleInputFocus = (e) => {
    e.target.select();
  };

  const handlePinChange = (index) => (e) => {
    if (e.target.value === '' || (e.target.value.length === 1 && /^[0-9]$/.test(e.target.value))) {
      setPin((prevPin) => {
        const newPin = [...prevPin];
        newPin[index] = e.target.value;
        return newPin;
      });
      if (e.target.nextSibling) {
        e.target.nextSibling.focus();
      }
    }
  };

  const handleBackspace = (index) => (e) => {
      if (e.key === "Backspace") {
        if (pin[index] !== "") {
          // If the current input is not empty, clear it and keep focus in the same place.
          setPin(prevPin => {
            const newPin = [...prevPin];
            newPin[index] = '';
            return newPin;
          });
        } else {
          // If the current input is already empty, prevent the Backspace key press from bubbling up to the browser and move focus to the previous input.
          e.preventDefault();

          if (e.target.previousSibling) {
            e.target.previousSibling.focus();
          }
        }
      }
    };


    const handleLogin = async (event) => {
      event.preventDefault();
    
      const url = new URL(`${config.apiEndpoint}/devices/login`);
      url.searchParams.append('name', name1 + '-' + name2);
      url.searchParams.append('pin', pin.join(''));
  
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('refresh_token', data.refresh_token);
          navigate('/');
        } else if (response.status === 503) {
          setToastMessage(t('toasts.login_deviceOffline'));
          setToastColor('red'); 
          setToastVisible(true);
        } else if (response.status === 401 || response.status === 404) {        
          setToastMessage(t('toasts.login_invalidCredentials'));
          setToastColor('red'); 
          setToastVisible(true);
        } else {
          setToastMessage(t('toasts.login_loginFailed'));
          setToastColor('red'); 
          setToastVisible(true);
        }      
      } catch (err) {
        alert(t('toasts.login_error', { message: err.message }));
      }
    };

  return (
    <div className="flex flex-col h-screen bg-purple-10">
      {toastVisible && <InfoToast emoji="⚠️" label={toastMessage} color={toastType} buttons={[{action: () => setToastVisible(false)}]} className='page-full' />}
      <div className="navbar w-full h-24 flex items-center justify-start px-8">
        <img src={logo} alt={t('login_logoAlt')} className="h-10 w-auto" />
      </div>
      <div className="login-wrapper">
        <div className="login-info-wrapper">
          <h1>{t('login_connectToYourTinyfarm')}</h1>
          <div className="login-info-text">{t('login_enterNameAndPin')}</div>
          <EPaperDisplay />
        </div>
        <div className="login-form-wrapper">
          <form className="login-form-content" onSubmit={handleLogin}>
            <div className="login-form-top">
              <label htmlFor="name1" className="login-label">{t('login_tinyfarmName')}</label>
              <div className="login-name-inputs login-inputs">
                <input
                  type="text"
                  id="name1"
                  value={name1}
                  onChange={(e) => setname1(e.target.value.trim())}
                  className="login-name-input"
                  placeholder='cute'
                  required
                />
                <div className="login-dash">-</div>
                <input
                  type="text"
                  id="name2"
                  value={name2}
                  onChange={(e) => setname2(e.target.value.trim())}
                  className="login-name-input"
                  placeholder='oyster'
                  required
                />
              </div>
            </div>
            <div className="login-form-bottom">
              <label htmlFor="pin" className="login-label">{t('login_tinyfarmPin')}</label>
              <div className="login-pin-inputs login-inputs">
                {pin.map((digit, i) => (
                  <input
                    key={i}
                    type="text"
                    ref={pinInputRefs[i]}
                    value={digit}
                    onChange={handlePinChange(i)}
                    onKeyDown={handleBackspace(i)}
                    onFocus={handleInputFocus}  
                    maxLength="1"
                    className="login-input"
                    required
                  />
                ))}
              </div>
            </div>
            <button type="submit" className="login-submit">
              {t('login_connect')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;