import './selectors.css';
import React, { useState, useEffect, useRef } from 'react';
import {ReactComponent as Chevron} from '../../assets/global/chevron.svg';

const Dropdown = ({ items, id, onChange, small = false, className = '', defaultIndex }) => {
  const initialIndex = typeof defaultIndex === 'number' ? defaultIndex : 0;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(items?.[initialIndex] || items?.[0]);  
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const dropdownRef = useRef(null);

  
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  
  useEffect(() => {
    
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, items]);

  useEffect(() => {
    if (onChange && selectedItem !== null) {
      onChange(selectedItem, selectedIndex);
    }
  }, [onChange, selectedItem, selectedIndex]);

  const toggleOpen = () => setIsOpen(!isOpen);

  const selectItem = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
    setIsOpen(false);
  };

  return (
    <div className={`dropdown ${className}`} id={id} ref={dropdownRef}>
      <div
        className={`dropdown-item dropdown-item-selected ${small ? 'small-selected' : ''}`}
        onClick={toggleOpen}
      >
        {selectedItem && (
          <>
            <div className="dropdown-svg">{selectedItem.svg}</div>
            {!small && <div className="dropdown-label">{selectedItem.label}</div>}
            <Chevron className="dropdown-chevron-icon" />
          </>
        )}
      </div>
      {isOpen && (
        <ul className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
          {items.map((item, index) => (
            <li key={index} onClick={() => selectItem(item, index)} className="dropdown-item">
              <div className="dropdown-svg">{item.svg}</div>
              <div className="dropdown-label">{item.label}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const NumberInput = ({ unit, value, onChange, min, max }) => {
  
  const [inputValue, setInputValue] = useState(value);
  
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Victor: Tried to get the value applied on Enter keypress. "If" works, but the value isn't applied.
  // const handleKeyUp = (event) => {
  //   if (event.key === 'Enter' ) {
  //     setInputValue(event.target.value);
  //   }
  // };
  
  const handleInputBlur = () => {
    let newValue = Number(inputValue);

    
    if (min !== undefined && newValue < min) {
      newValue = min;
    } else if (max !== undefined && newValue > max) {
      newValue = max;
    }

    
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  
  const handleClick = (event) => {
    event.target.select();
  };

  
  return (
    <div className="number-input">
      <input 
        type="number"
        value={inputValue}
        onChange={handleInputChange}
        // onKeyUp={handleKeyUp}
        onBlur={handleInputBlur}
        onClick={handleClick}
        className="number-input-field"
        min={min} 
        max={max}
      />
      <div className="number-input-unit">{unit}</div>
    </div>
  );
};



const StaticInput = ({name}) => {

  return (
    <div className="static-input">
      <div className="static-input-field">{name}</div>
    </div>
  );
};


const CombinedSelector = ({ children }) => {
    return (
      <div className="combined-selector">
        {children.map((Child, index) => (
          <div key={index} className="selector-item">
            {Child}
          </div>
        ))}
      </div>
    );
  };
  

export {Dropdown, NumberInput, StaticInput, CombinedSelector};
