import './course.css'
import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumb from './pages/learning/breadCrumb'
import { HeaderElements } from './pages/learning/headerElements'
import { useTranslation } from 'react-i18next'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import CircularProgress from '@mui/material/CircularProgress';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function CourseContent({ content }) {
  const pdfURL = content?.data?.attributes?.url;
  if (!pdfURL) {
      return <div>No PDF available.</div>;
  }
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
      <div className="course-content-container">
          {/* if you change the workerurl it will break the viewer */}
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer fileUrl={pdfURL} 
              plugins={[defaultLayoutPluginInstance]}/>
          </Worker>
      </div>
  );
}


function Course() {
    const { t, i18n } = useTranslation(['learning', 'global']);
    const mainLocale = i18n.language.split('-')[0];
    const [course, setSingleCourse] = useState(null);

    const {number} = useParams();  
    useEffect(() => {
        const fetchSingleCourse = async () => {
          try {
            const response = await fetch(
              `https://cdn.tinyfar.ms/api/courses?filters[unique_id][$eq]=${number}&locale=${mainLocale}&populate=deep`,
              {
                headers: {
                  'Authorization': `Bearer d1333c56689617833867bcf47416a66cf35d6235a9743656411dff740fa775caa3f133b2b5f3c36d953e4f67ea4ce62b9963bf674a501b6120bbb784f17bacd3c2fda1661c2181dcc2235800ae565213528d106a12af689a026df0a08e8d3b73c4da77e09cb1ce30261e42b523495e4b701923488ada79d18c85ff502bc46cfc`
                }
              }
            );
    
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(`Server responded with ${response.status}: ${errorData.message || response.statusText}`);
            }

            const data = await response.json();
            setSingleCourse(data.data[0]);
    
          } catch (err) {
            console.error(`Error fetching course: ${err.message}`);
          }
        };
    
        fetchSingleCourse();
      }, []);
      
  
      return (
        <div className="lesson-container flex flex-col items-center justify-start w-full h-full">
            { !course ? (
                <>
                <div className="action-bar">
                  <BreadCrumb name={`${t('all_lessons')}`} />
                </div>
                <div className="loading-placeholder loading-placeholder-container">
                  <CircularProgress />
                  <h4>{t('loading', { ns: 'global' })}</h4>
                </div>
                </>
            ) : (
                <>
                <div className="action-bar">
                    <BreadCrumb name={`${t('all_lessons')} › ${t('lesson')} ${course.attributes.unique_id}`} />        
                    <HeaderElements page="lesson" number={number} />
                </div>
                <CourseContent content={course.attributes.content} />
                </>
            )}
        </div>
    );
}

export default Course; 