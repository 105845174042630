import React from 'react';

const DeleteSensor = ({ onClick }) => { 
    return (
        <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7,5 L17,5 L17,19 L7,19 L7,5 Z M9,7 L9,17 L11,17 L11,7 L9,7 Z M13,7 L13,17 L15,17 L15,7 L13,7 Z" fill="#7870E6"/>
            <rect x="6" y="4" width="12" height="1" fill="#7870E6"/>
            <rect x="10" y="2" width="4" height="2" fill="#7870E6"/>
        </svg>

    );
};

export default DeleteSensor;
