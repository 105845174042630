import React, { useState, useEffect } from 'react';
import './slider.css';
import {nmToRgb} from '../../functions/utils/light.js';

const SimpleSlider = ({ min, max, active, value, onChange, onMouseUp }) => {
  const progress = (value - min) / (max - min) * 100;
  
  const handleSliderChange = (e) => {
      if(onChange) {
          onChange(e.target.value);
      }
  };

  const handleMouseUp = (e) => {
      if(onMouseUp) {
          onMouseUp(e); 
      }
  };

  return (
      <div className={`slider-container ${active ? "slider-active" : "slider-inactive"}`}>
          <input 
              type="range" 
              min={min} 
              max={max} 
              value={value} 
              onChange={handleSliderChange} 
              onMouseUp={handleMouseUp} 
              className={`slider ${active ? "slider-active" : "slider-inactive"}`}
              disabled={active}
              style={{
                background: `linear-gradient(to right, var(--purple-40) 0%, var(--purple-40) ${progress}%, var(--purple-10, #F0F0FF) ${progress}%, var(--purple-10, #F0F0FF) 100%)`,
                opacity: active ? 0.4 : 1
              }}
          />
      </div>
  );
};

const IntervalSlider = ({ initialValue, timeValues, onChange, onMouseUp }) => {
  
  const initialIndex = timeValues.findIndex(t => t.value === initialValue);
  const [sliderIndex, setSliderIndex] = useState(initialIndex >= 0 ? initialIndex : 0);
  
  const handleSliderChange = (e) => {
    const newSliderIndex = Number(e.target.value);
    setSliderIndex(newSliderIndex);
    const selectedTime = timeValues[newSliderIndex];
    onChange(selectedTime.value); 
  };
  
  const handleMouseUp = () => {
      const selectedTime = timeValues[sliderIndex];
      onMouseUp(selectedTime.value); 
  };

  useEffect(() => {
    const newIndex = timeValues.findIndex(t => t.value === initialValue);
    if (newIndex >= 0) {
      setSliderIndex(newIndex);
    }
  }, [initialValue, timeValues]);

  return (
    <div className="slider-container slider-active">
      <input
        type="range"
        min="0"
        max={timeValues.length - 1}
        value={sliderIndex}
        onChange={handleSliderChange}
        onMouseUp={handleMouseUp}
        className="slider slider-interval"
      />
    </div>
  );
};

const IntensitySlider = ({ min, max, value, onMouseUp}) => {
  const [sliderValue, setSliderValue] = useState(value);


  useEffect(() => {
    setSliderValue(value); 
  },[value]);

  const handleSliderChange = (e) => {
    setSliderValue(e.target.value); 
  };

  const handleMouseUp = (e) => {
    onMouseUp(e.target.value);
  };

  const updatedProgress = (sliderValue-min) / (max-min) * 100;
  
  return (
      <div className={`slider-container`}>
          <style>
              {`
              .slider-intensity::-moz-range-track {
                background: linear-gradient(to right, var(--purple-40) 0%, var(--purple-40) ${updatedProgress}%, var(--purple-10) ${updatedProgress}%, var(--purple-10) 100%); 
              }`}
          </style>
          <input 
              type="range" 
              min={min} 
              max={max} 
              value={sliderValue} 
              onChange={handleSliderChange}
              onMouseUp={handleMouseUp}
              className={`slider slider-intensity`}
              style={{
                background: `linear-gradient(to right, var(--purple-40) 0%, var(--purple-40) ${updatedProgress}%, var(--purple-10) ${updatedProgress}%, var(--purple-10) 100%)`,
              }}
          />
      </div>
  );
};


const ColorSlider = ({ id, min, max, value, onMouseUp}) => {
  const storedColor = sessionStorage.getItem(`color-${id}`);
  const [currentColor, setCurrentColor] = useState(storedColor);
  const [sliderValue, setSliderValue] = useState(value);
  
  useEffect(() => {
    setSliderValue(value);
  }, [value]);
  
  useEffect(() => {
    sessionStorage.setItem(`color-${id}`, currentColor); 
    const style = document.createElement('style');
    style.innerHTML = `
    .slider.color-slider::-webkit-slider-thumb {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), ${currentColor};
    }
    .slider.color-slider::-webkit-slider-runnable-track {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(90deg, rgba(255, 0, 255, 1) 0%, rgba(0, 0, 255, 1) 15%, rgba(0, 255, 255, 1) 30%, rgba(0, 255, 0, 1) 37.5%, rgba(255, 255, 0, 1) 60%, rgba(255, 0, 0, 1) 80%);
    }
    .slider.color-slider::-moz-range-track{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(90deg, rgba(255, 0, 255, 1) 0%, rgba(0, 0, 255, 1) 15%, rgba(0, 255, 255, 1) 30%, rgba(0, 255, 0, 1) 37.5%, rgba(255, 255, 0, 1) 60%, rgba(255, 0, 0, 1) 80%);
    }
  `
  ;
  document.documentElement.style.setProperty('--global-svg-color', currentColor);
  document.head.appendChild(style);
  
  return () => { // Clean up function
    document.head.removeChild(style);
  };
}, [id, currentColor]);


const handleSliderChange = (e) => {
  const newValue = e.target.value;
  setSliderValue(newValue);
  
};
const handleMouseUp = (e) => {
  const newColor = nmToRgb(e.target.value);
  setCurrentColor(newColor);
  onMouseUp(e.target.value);
};

return (
  <div className="slider-container">
    <input
      type="range"
      min={min}
      max={max}
      value={sliderValue}  // use local state for the displayed value
      onChange={handleSliderChange}
      onMouseUp={handleMouseUp}
      className="slider color-slider"
    />
  </div>
);
};

const MemoizedIntervalSlider = React.memo(IntervalSlider);
export { SimpleSlider, MemoizedIntervalSlider as IntervalSlider, IntensitySlider, ColorSlider };